import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Form, Header, Loader, Segment, Table } from 'semantic-ui-react';
import { RootState } from '../../../store/store';
import { fetchMessages, selectMessagesByUserId, selectMessagesStatus } from '../../../store/reducers/messagesSlice';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { selectAllParticipantsIDs } from '../../../store/reducers/participantsSlice';
import { RequestStatuses } from '../../../types/status';
import { useEffect } from 'react';

export default function ViewMessagesView (): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  // Get the userId param from the URL.
  const { userId } = useParams();
  const messages = useSelector((state: RootState) => selectMessagesByUserId(state, userId));
  const messagesStatus = useSelector(selectMessagesStatus);
  const participantIds = useSelector(selectAllParticipantsIDs);

  useEffect(() => {
    if (userId != null && messages === undefined) {
      void dispatch(fetchMessages(userId));
    }
  }, [userId, messages]);

  const onChange = (id: string): void => {
    navigate({ pathname: id });
  };

  const getMessagesTable = (): JSX.Element => {
    const tableRows = [];
    if (messagesStatus === RequestStatuses.loading) {
      tableRows.push(<Table.Row key='row-loading'><Table.Cell colSpan={3}><Loader active inline='centered' /></Table.Cell></Table.Row>);
    } else if (messages === undefined || messages.length === 0) {
      tableRows.push(<Table.Row key='row-empty'><Table.Cell colSpan={3}>No messages</Table.Cell></Table.Row>);
    } else {
      messages.forEach((m, i) => {
        tableRows.push(<Table.Row key={`row-meesage-${i}`} >
          <Table.Cell>{new Date(m.epoch).toLocaleString()}</Table.Cell>
          <Table.Cell>{m.content}</Table.Cell>
          <Table.Cell>{m.sentBy}</Table.Cell>
      </Table.Row>);
      }
      );
    }
    return (
      <Form.Field>
        <Table celled unstackable>
            <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Sent By</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tableRows}
            </Table.Body>
        </Table>
      </Form.Field>
    );
  };

  return (
    <Segment className="View">
      <Header size='medium'>Recent Messages</Header>
      <Form>
        <Form.Field>
          <label>User:</label>
          <Dropdown
              placeholder='Select user'
              search
              selection
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              options={participantIds.slice(0).sort((p1, p2) => p1.length - p2.length || p1.localeCompare(p2)).map(s => ({
                key: s,
                value: s,
                text: s
              }))}
              value={userId}
              onChange={(e, { value }) => {
                onChange(value as string);
              }}
          />
        </Form.Field>
        {getMessagesTable()}
      </Form>
    </Segment>
  );
}
