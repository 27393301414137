import { useEffect } from 'react';
import StudyList from './StudyList';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchStudies, selectAllStudies, selectStudiesStatus } from '../../../store/reducers/studiesSlice';
import StudyOverview from './StudyOverview';

export default function StudyData (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const studies = useSelector(selectAllStudies);
  const status = useSelector(selectStudiesStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle && studies.length === 0) {
      void dispatch(fetchStudies());
    }
  }, [status, studies.length, dispatch]);

  return (
        <div className="study-data Page column">
            <StudyList studies={studies} />
            <StudyOverview />
        </div>
  );
}
