import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { selectParticipantsStatus, selectParticipantData, selectParticipantsError } from '../../../store/reducers/participantsSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { ParticipantData } from '../../../types/UserData';
import Graph, { GraphData } from './Graph';

interface DataGraphsProps {
  participantId: string | undefined
  selectedDate: number | undefined
  setSelectedDate: Function
}

/**
 * Displays selected participants data breakdown in a table
 */
export default function DataGraphs ({ participantId }: DataGraphsProps): JSX.Element {
  const status = useSelector(selectParticipantsStatus);
  const errorMessage = useSelector(selectParticipantsError);
  const participantData = useSelector((state: RootState) => selectParticipantData(state, participantId));
  const [searchParams, setSearchParams]: [URLSearchParams, Function] = useSearchParams();

  const swapSelectedDate = (dateString: string): void => {
    searchParams.set('selectedDate', dateString);
    setSearchParams(searchParams);
  };

  const getGraphs = (participantData: ParticipantData): JSX.Element[] => {
    const graphs: JSX.Element[] = [];
    const dates = participantData.data.map(e => e[0]);
    for (let i = 1; i < participantData.headers.length; i++) {
      const header = participantData.headers[i];
      const values = participantData.data.map(e => e[i]);
      const graphData: GraphData[] = [];
      dates.forEach((d, index) => {
        graphData.push({ date: new Date(d).getTime(), value: values[index] });
      });
      graphs.push(
        <Graph
          key={i}
          header={header}
          data={graphData}
          selectDate={swapSelectedDate}
          selectedDate={searchParams.get('selectedDate')}
          startDate={searchParams.get('startTime')}
          endDate={searchParams.get('endTime')}
        />);
    }
    return graphs;
  };

  const getDataTables = (): JSX.Element => {
    if (participantId === undefined) {
      return (
        <>Select a user from the list</>
      );
    }

    if ((participantData !== undefined) && participantData.data.length !== 0) {
      return (<>{getGraphs(participantData)}</>);
    }

    if (errorMessage !== undefined) {
      return (
        <>{errorMessage}</>
      );
    }

    return (
      <>No Data Available</>
    );
  };

  return (
    <div className="Page data-graphs column">
      <Dimmer active={status === RequestStatuses.loading}>
        <Loader active={true} />
      </Dimmer>
      {getDataTables()}
    </div>
  );
}
