import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FC, PropsWithChildren } from 'react';
import { RootState } from '../store/store';
import { UserRole, UserRoles } from '../types/UserData';

interface PrivateRouteProps extends PropsWithChildren {
  restrictedRoles: UserRole[]
}

/**
 * Functional React component that checks for authenticated user to decide whether to render the route
 * @param {React.Component} component component to render on valid authentication
 * @returns
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({ children, restrictedRoles }): any => {
  // use selector allows to access the redux store state in a functional react component
  // This will create a local member 'user' that is tied to the 'user' on the 'userStore' in redux.
  const { user } = useSelector((state: RootState) => state.userStore);
  const { pathname } = useLocation();

  if (user == null) {
    // not logged in so redirect to login page with the return url
    return (<Navigate to="/login" state={{ from: pathname }} />);
  }

  // Check the user role is permitted to view this route
  if (!restrictedRoles.includes(user.role)) {
    // Based on the role - redirect them to their usual landing page
    // (Admins can view all pages - so only check for Analyst)
    if (user.role === UserRoles.Analyst) {
      return (<Navigate to="/user-data" />);
    }
  }

  // authorized so return child components
  return children;
};
