import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar } from 'react-date-range';
import { client } from '../../../api/client';
import { useSelector } from 'react-redux';
import { selectAuthUserToken } from '../../../store/reducers/userReducer';
import { Dimmer, Loader } from 'semantic-ui-react';

interface SurveyDate {
  datetime: number
  alerts: string[]
}

interface SurveyCalendarState {
  loading: boolean
  error: string | undefined
  surveyDates: SurveyDate[]
}

export default function SurveyCalendar (): JSX.Element {
  const { userId } = useParams();
  const { date } = useParams();

  const navigate = useNavigate();

  const authToken = useSelector(selectAuthUserToken);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date != null ? new Date(parseInt(date)) : undefined);
  const [dateInMonth, setDateInMonth] = useState<Date>(new Date());
  const [surveyCalendarState, setSurveyCalendarState] = useState<SurveyCalendarState>({
    loading: false,
    error: undefined,
    surveyDates: []
  });

  useEffect(() => {
    if (date != null) {
      setSelectedDate(new Date(parseInt(date)));
    } else {
      setSelectedDate(undefined);
    }
  }, [date]);

  useEffect(() => {
    loadSelectedMonthSurveyDates();
  }, [userId]);

  useEffect(() => {
    loadSelectedMonthSurveyDates();
  }, [dateInMonth]);

  const onDateChange = (newDate: Date): void => {
    if (userId != null) {
      // Find the exact survey datetime that matches the day of the selected newDate
      const surveyDate = surveyCalendarState.surveyDates.find(sd => new Date(sd.datetime).toDateString() === newDate.toDateString());
      if (surveyDate != null) {
        navigate(`${userId}/survey/${surveyDate.datetime.toString()}`);
      }
    }
  };

  const onShownDateChange = (arg1: Date): void => {
    setDateInMonth(arg1);
  };

  const loadSelectedMonthSurveyDates = (): void => {
    if (userId != null) {
      // Fetch user survey completion dates from the first and the last of the current month
      const firstDay = new Date(dateInMonth.getFullYear(), dateInMonth.getMonth(), 1);
      const lastDay = new Date(dateInMonth.getFullYear(), dateInMonth.getMonth() + 1, 0);

      const searchParams = new URLSearchParams();
      searchParams.set('from', firstDay.getTime().toString());
      searchParams.set('to', lastDay.getTime().toString());
      // Call an API and wait for response
      setSurveyCalendarState({ ...surveyCalendarState, error: undefined, loading: true });
      client.get(`/api/participants/${userId}/survey?` + searchParams.toString(), {}, authToken)
        .then(res => {
          setSurveyCalendarState({ error: undefined, loading: false, surveyDates: res.data });
        })
        .catch(err => {
          console.error(err);
          setSurveyCalendarState({ ...surveyCalendarState, error: 'Failed to load the completed dates for the month', loading: false });
        });
    }
  };

  const customDayContent = (day: Date): JSX.Element => {
    let extraDot = null;
    const surveyDate = surveyCalendarState.surveyDates.find(sd => new Date(sd.datetime).toDateString() === day.toDateString());
    if (surveyDate != null) {
      const style: React.CSSProperties = {
        height: '5px',
        width: '5px',
        borderRadius: '100%',
        background: 'green',
        position: 'absolute',
        top: 2,
        right: 2
      };
      if (surveyDate.alerts.length > 0) {
        style.background = 'red';
      }
      extraDot = (
        <div style={ style } />
      );
    }
    return (
      <div>
        {extraDot}
        <span>{day.getDate()}</span>
      </div>
    );
  };

  const disabledDay = (day: Date): boolean => {
    const surveyDate = surveyCalendarState.surveyDates.find(sd => new Date(sd.datetime).toDateString() === day.toDateString());
    return surveyDate == null;
  };

  return (
    <>
        <Dimmer active={surveyCalendarState.loading}>
            <Loader active={true} />
        </Dimmer>
        <Calendar
            onChange={item => onDateChange(item)}
            onShownDateChange={onShownDateChange}
            date={selectedDate}
            disabledDay={disabledDay}
            dayContentRenderer={customDayContent}
        />
    </>
  );
}
