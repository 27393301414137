import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button, Dimmer, Dropdown, Form, Loader, Segment } from 'semantic-ui-react';
import { selectParticipantsStatus, selectParticipantById } from '../../../store/reducers/participantsSlice';
import { selectAuthUserToken } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { Participant } from '../../../types/UserData';

interface ParticipantsProps {
  participants: Participant[]
}

export default function ParticipantsList ({ participants }: ParticipantsProps): JSX.Element {
  const { participantId } = useParams();
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();
  const [exporting, setExporting] = useState(false);

  const navigate = useNavigate();

  const status = useSelector(selectParticipantsStatus);
  const participant = useSelector((state: RootState) => selectParticipantById(state, participantId));
  const authToken = useSelector((state: RootState) => selectAuthUserToken(state));

  // Get the time range from search parameters
  const searchEndTime = searchParams.get('endTime');
  const searchStartTime = searchParams.get('startTime');

  // If the search parameters contains defined time range - use them, otherwise default times to the past day;
  const initialEndTime = (searchEndTime != null) ? new Date(searchEndTime) : new Date();
  const initialStartTime = (searchStartTime != null) ? new Date(searchStartTime) : new Date(new Date().setDate(initialEndTime.getDate() - 1));

  const [selectedId, setSelectedId] = useState<string | undefined>(participantId);
  const [startTime, setStartTime] = useState<string | undefined>(initialStartTime.toISOString().substring(0, 16));
  const [endTime, setEndTime] = useState<string | undefined>(initialEndTime.toISOString().substring(0, 16));

  const onChange = (id: string): void => {
    setSelectedId(id);
  };

  const search = (): void => {
    if (selectedId !== undefined && startTime !== undefined && endTime !== undefined) {
      searchParams.set('startTime', startTime);
      searchParams.set('endTime', endTime);
      searchParams.set('view', 'data');
      navigate({
        pathname: selectedId,
        search: searchParams.toString()
      });
    }
  };

  const actionDisabled = (): boolean => {
    return selectedId === undefined || startTime === undefined || endTime === undefined;
  };

  const exportUserData = (): void => {
    if (selectedId !== undefined && startTime !== undefined && endTime !== undefined && authToken !== undefined) {
      const config: RequestInit = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + authToken
        }
      };

      const filename = selectedId + '-' + startTime + '-' + endTime + '.csv';
      const searchParams = new URLSearchParams({ startTime, endTime });
      setExporting(true);
      void fetch(`${process.env.REACT_APP_API_URL as string}/api/participants/${selectedId}/data/export?` + searchParams.toString(), config)
        .then(async (res) => {
          if(res.ok) {
            return res.json()
            .then(json => {
              if(json.downloadUrl) {
                fetch(json.downloadUrl)
                .then(async downloadRes => {
                  return await downloadRes.blob();
                })
                .then((blob) => {
                  const href = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = href;
                  link.setAttribute('download', filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                })
                .catch(async (err) => {
                  console.error('Failed to download', err);
                })
                .finally(() => { setExporting(false) });
              }
            })
          }
          console.error("Failed to export the data")
        })
        .catch(async (err) => {
          console.error('Failed to download', err);
        })
        .finally(() => { setExporting(false) })
    }
  };

  const getFilterOptions = (): JSX.Element => {
    if (participant !== null) {
      return (
        <>
          <Form.Field>
            <label>Start Time:</label>
            <input placeholder='Start Time'
              type={'datetime-local'}
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>End Time:</label>
            <input placeholder='End Time'
              type={'datetime-local'}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </Form.Field>
          <Form.Group>
            <Button
              disabled={actionDisabled()}
              onClick={search}
              >
              Search
            </Button>
            <Button
              disabled={actionDisabled() || exporting}
              onClick={exportUserData}
              loading={exporting}
              >
              Export
            </Button>
          </Form.Group>
        </>
      );
    }
    return <></>;
  };

  return (
        <Segment className="View study-info" key="study-info">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <Form>
                <Form.Field>
                    <label>User:</label>
                    <Dropdown
                        placeholder='Select user'
                        selection
                        search
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        options={participants.slice(0).sort((p1, p2) => p1.id.length - p2.id.length || p1.id.localeCompare(p2.id)).map(s => ({
                          key: s.id,
                          value: s.id,
                          text: s.id
                        }))}
                        value={selectedId}
                        onChange={(e, { value }) => {
                          onChange(value as string);
                        }
                        }
                    />
                </Form.Field>
                {getFilterOptions()}
            </Form>
        </Segment>
  );
}
