import { createSlice, createAsyncThunk, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { Message } from '../../types/UserData';
import { RootState } from '../store';

// Define a type for the slice state
interface ParticipantIdToMessages {
  [key: string]: Message[] | undefined
}

interface MessagesState {
  messages: ParticipantIdToMessages
  status: RequestStatus
  sendingStatus: RequestStatus
  error: string | undefined
  sendingError: string | undefined
}

const initialState: MessagesState = {
  messages: {},
  status: RequestStatuses.idle,
  sendingStatus: RequestStatuses.idle,
  error: undefined,
  sendingError: undefined
};

export const fetchMessages = createAsyncThunk(
  'participants/fetchMessages',
  async (userId: string, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.get(`/api/participants/${userId}/messages`, {}, authToken);
    return response.data;
  }
);

export const sendMessage = createAsyncThunk(
  'participants/sendMessage',
  async (arg: { userId: string, message: Message }, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post(`/api/participants/${arg.userId}/messages`, arg.message, {}, authToken);
    return response.data;
  }
);

export const sendMessageToAll = createAsyncThunk(
  'participants/sendMessageToAll',
  async (message: Message, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post('/api/participants/messages', message, {}, authToken);
    return response.data;
  }
);

export const sendGroupMessage = createAsyncThunk(
  'participants/sendGroupMessage',
  async (arg: { studyId: string, message: Message }, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post(`/api/studies/${arg.studyId}/messages`, arg.message, {}, authToken);
    return response.data;
  }
);

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {

  },
  extraReducers (builder: ActionReducerMapBuilder<MessagesState>) {
    builder
      .addCase(fetchMessages.pending, (state: MessagesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchMessages.fulfilled, (state: MessagesState, action: PayloadAction<Message[], any, any>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        state.messages[action.meta.arg] = action.payload;
      })
      .addCase(fetchMessages.rejected, (state: MessagesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(sendMessage.pending, (state: MessagesState) => {
        state.sendingStatus = RequestStatuses.loading;
      })
      .addCase(sendMessage.fulfilled, (state: MessagesState, action: PayloadAction<Message, any, any>) => {
        state.sendingError = undefined;
        state.sendingStatus = RequestStatuses.success;
        const userId = action.meta.arg.userId;
        if (state.messages[userId] === undefined) {
          state.messages[userId] = [];
        }
        state.messages[userId]?.push(action.meta.arg.message);
      })
      .addCase(sendMessage.rejected, (state: MessagesState, action: PayloadAction<any, any, any, any>) => {
        state.sendingStatus = RequestStatuses.failed;
        state.sendingError = action.error.message;
      })
      .addCase(sendGroupMessage.pending, (state: MessagesState) => {
        state.sendingStatus = RequestStatuses.loading;
      })
      .addCase(sendGroupMessage.fulfilled, (state: MessagesState, action: PayloadAction<string[], any, any>) => {
        state.sendingError = undefined;
        state.sendingStatus = RequestStatuses.success;
        // The payload contains list of user IDs the message was sent to
        const userIds = action.payload;
        if (userIds != null) {
          for (const userId of userIds) {
            if (state.messages[userId] === undefined) {
              state.messages[userId] = [];
            }
            state.messages[userId]?.push(action.meta.arg.message);
          }
        }
      })
      .addCase(sendGroupMessage.rejected, (state: MessagesState, action: PayloadAction<any, any, any, any>) => {
        state.sendingStatus = RequestStatuses.failed;
        state.sendingError = action.error.message;
      })
      .addCase(sendMessageToAll.pending, (state: MessagesState) => {
        state.sendingStatus = RequestStatuses.loading;
      })
      .addCase(sendMessageToAll.fulfilled, (state: MessagesState, action: PayloadAction<string[], any, any>) => {
        state.sendingError = undefined;
        state.sendingStatus = RequestStatuses.success;
        // Push the message into all arrays that have already been loaded by the UI
        const message = action.meta.arg;
        for (const userId of Object.keys(state.messages)) {
          if (state.messages[userId] === undefined) {
            state.messages[userId] = [];
          }
          state.messages[userId]?.push(message);
        }
      })
      .addCase(sendMessageToAll.rejected, (state: MessagesState, action: PayloadAction<any, any, any, any>) => {
        state.sendingStatus = RequestStatuses.failed;
        state.sendingError = action.error.message;
      });
  }
});

export default messagesSlice.reducer;

export const selectMessagesStatus = (state: RootState): RequestStatus => state.messagesStore.status;
export const selectSendingStatus = (state: RootState): RequestStatus => state.messagesStore.sendingStatus;
export const selectMessagesError = (state: RootState): string | undefined => state.messagesStore.error;
export const selectSendingError = (state: RootState): string | undefined => state.messagesStore.sendingError;

export const selectMessagesByUserId = (state: RootState, userId: string | undefined): Message[] | undefined => {
  if (userId === undefined) {
    return undefined;
  }
  return state.messagesStore.messages[userId];
};
