import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Form, DropdownProps, Segment, Message } from 'semantic-ui-react';
import { isNilOrWhitespace } from '../../../common/utils';
import { addNewUser, selectUsersError, selectUsersStatus } from '../../../store/reducers/usersSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { UserData, UserRole, UserRoles } from '../../../types/UserData';

export default function NewUserInfo (): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const [password] = useState<string>('**********');
  const [role, setRole] = useState<UserRole | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const users = useSelector((state: RootState) => state.usersStore.users);
  const usersLength = useRef(users.length);
  const usersStatus = useSelector(selectUsersStatus);
  const error = useSelector(selectUsersError);

  const save = (): void => {
    if (validateForm()) {
      const newUser: UserData = {
        username: username as string,
        role: role as UserRole
      };
      void dispatch(addNewUser(newUser));
    }
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate('..');
  };

  const validateForm = (): boolean => {
    return role !== undefined && !isNilOrWhitespace(username);
  };

  useEffect(() => {
    if (usersLength.current !== users.length) {
      // User was saved - navigate to user info view
      navigate('../' + users[users.length - 1].username + '');
    }
  }, [navigate, users]);

  return (
        <Segment className="View User-info admin">
            <Form >
                <Form.Field >
                    <label data-lpignore='true'>Username:</label>
                    <input
                        type='text'
                        autoComplete='off'
                        data-lpignore='true'
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username} />
                </Form.Field>
                <Form.Field>
                    <label>Role:</label>
                    <Dropdown
                        placeholder='Select role'
                        selection
                        options={[{ key: UserRoles.Admin, value: UserRoles.Admin, text: 'Admin' },
                          { key: UserRoles.Analyst, value: UserRoles.Analyst, text: 'Analyst' }]}
                        value={role}
                        onChange={(_e: SyntheticEvent, { value }: DropdownProps) => {
                          // safety check that the selected value is one of the expected enum types,
                          // Before it is used as that type
                          if (Object.values(UserRoles).includes(value as UserRole)) {
                            setRole(value as UserRole);
                          }
                        }
                        }
                    />
                </Form.Field>
                <Form.Field>
                    <label>Password:</label>
                    <input autoComplete='off'
                      data-lpignore='true'
                      readOnly={true}
                      value={password} />
                </Form.Field>
            </Form>
            { error != null ? <Message color='red'>Error occured. Try again.</Message> : null }
            <div className='list-actions'>
              <Button loading={usersStatus === RequestStatuses.loading} onClick={cancel}>
                Cancel
              </Button>
              <Button loading={usersStatus === RequestStatuses.loading} onClick={save} disabled={!validateForm()}>
                Save
              </Button>
            </div>
        </Segment>
  );
}
