import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Segment } from 'semantic-ui-react';
import { selectMessageTemplateById, selectMessageTemplateStatus, updateMessageTemplate } from '../../../store/reducers/messageTemplatesReducer';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { MessageTemplate } from '../../../types/UserData';

export default function MessageTemplateEdit (): JSX.Element {
  // Get the userId param from the URL.
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const messageTemplate = useSelector((state: RootState) => selectMessageTemplateById(state, id));
  const status = useSelector(selectMessageTemplateStatus);
  const current = useRef(status);

  if (messageTemplate == null) {
    return (
      <Segment className="View User-info">
          Select a message template from the list or add a new one
      </Segment>
    );
  }

  const [message, setMessage] = useState(messageTemplate.content);

  const save = (): void => {
    const updatedMessage: MessageTemplate = {
      id: messageTemplate.id,
      content: message
    };
    void dispatch(updateMessageTemplate(updatedMessage));
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate(-1);
  };

  useEffect(() => {
    if (current.current !== status) {
      // If success from loading - navigate back
      if (status === RequestStatuses.success && current.current === RequestStatuses.loading) {
        navigate(-1);
      }
      current.current = status;
    }
  }, [status]);

  return (
    <Segment className="View User-info admin" key={id}>
        <Form>
            <Form.Field>
                <label>Message:</label>
                <textarea value={message} onChange={(e) => {
                  setMessage(e.target.value);
                }}/>
            </Form.Field>
        </Form>
        <div className='list-actions'>
          <Button loading={status === RequestStatuses.loading} onClick={cancel}>Cancel</Button>
          <Button loading={status === RequestStatuses.loading} onClick={save}>Save</Button>
        </div>
    </Segment>
  );
}
