import { Icon, SemanticICONS } from 'semantic-ui-react';

interface RatedAnswer {
  label: string
  rating: number | string
}

interface SurveyData {
  title: string
  question: string
  answer: string | RatedAnswer[]
}

interface SurveyAnswerSegmentProps {
  surveyQA: SurveyData
  renderIcons: boolean
  headerLabel?: string
  headerRating?: string
}

export default function SurveyAnswerSegment ({ surveyQA, renderIcons, headerLabel = 'Type', headerRating = 'Rating' }: SurveyAnswerSegmentProps): JSX.Element {
  const getAnswer = (answer: string | RatedAnswer[]): JSX.Element => {
    if (typeof answer === 'string') {
      return (<div className="survey-answer">{answer}</div>);
    } else {
      return (
            <table>
                <thead>
                    <tr><th>{headerLabel}</th><th>{headerRating}</th></tr>
                </thead>
                <tbody>
                    {answer.map((a, i) => (<tr key={`sa-${i}`}><td>{a.label}</td><td>{a.rating}</td></tr>))}
                </tbody>
            </table>
      );
    }
  };

  const getIcon = (iconName: SemanticICONS, alt: string): JSX.Element => {
    if (renderIcons) {
      return <Icon name={iconName} size='large'/>;
    }
    return <div>{alt}</div>;
  };

  return (
        <div className="survey-q-and-a">
            <div className="survey-icon-and-title">
                {getIcon('question', 'Q: ')}
                <div className="survey-t-and-q">
                    <div className="survey-title">{surveyQA.title}</div>
                    <div className="survey-question">{surveyQA.question}</div>
                </div>
            </div>
            <div className='survey-icon-and-title'>
                {getIcon('talk', 'A: ')}
                {getAnswer(surveyQA.answer)}
            </div>
        </div>
  );
}
