import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import LoginView from './loggedOut/views/login';
import ErrorView from './common/ErrorView';
import UserManagement from './loggedIn/views/userManagement';
import { PrivateRoute } from './common/PrivateRoute';
import { UserRoles } from './types/UserData';
import UserInfo from './loggedIn/views/userManagement/UserInfoView';
import NewUserInfo from './loggedIn/views/userManagement/NewUserInfoView';
import StudyData from './loggedIn/views/studyData';
import UserData from './loggedIn/views/userData';
import StudyManagement from './loggedIn/views/studyManagement';
import StudyInfo from './loggedIn/views/studyManagement/StudyInfoView';
import NewStudyInfo from './loggedIn/views/studyManagement/NewStudyInfoView';
import ParticipantManagement from './loggedIn/views/participantManagement';
import NewParticipantInfo from './loggedIn/views/participantManagement/NewParticipantInfo';
import ParticipantInfo from './loggedIn/views/participantManagement/ParticipantInfoView';
import EditParticipantInfo from './loggedIn/views/participantManagement/EditParticipantInfo';
import PasswordResetView from './loggedIn/views/passwordReset';
import Messaging from './loggedIn/views/messaging';
import MessageTemplates from './loggedIn/views/message-templates';
import MessageTemplateEdit from './loggedIn/views/message-templates/MessageTemplateEdit';
import MessageTemplateInfo from './loggedIn/views/message-templates/MessageTemplateInfo';
import MessageTemplateAdd from './loggedIn/views/message-templates/MessageTemplateAdd';
import SurveyResults from './loggedIn/views/surveyResults';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
    children: [
      {
        index: true,
        element: <LoginView />,
        errorElement: <ErrorView />
      },
      {
        path: 'login',
        element: <LoginView />,
        errorElement: <ErrorView />
      },
      {
        path: 'change-password',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin, UserRoles.Analyst]}><PasswordResetView /></PrivateRoute>,
        errorElement: <ErrorView />
      },
      {
        path: 'admin-management',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin]}><UserManagement /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            path: '',
            element: <UserInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId',
            element: <UserInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId/edit',
            element: <UserInfo />,
            errorElement: <ErrorView />
          },
          {
            path: 'add',
            element: <NewUserInfo />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'participant-management',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin]}><ParticipantManagement /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            path: '',
            element: <ParticipantInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId',
            element: <ParticipantInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId/edit',
            element: <EditParticipantInfo />,
            errorElement: <ErrorView />
          },
          {
            path: 'add',
            element: <NewParticipantInfo />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'study-management',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin]}><StudyManagement /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            path: '',
            element: <StudyInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':studyId',
            element: <StudyInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':studyId/edit',
            element: <StudyInfo />,
            errorElement: <ErrorView />
          },
          {
            path: 'add',
            element: <NewStudyInfo />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'user-data',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin, UserRoles.Analyst]}><UserData /></PrivateRoute>,
        children: [
          {
            index: true,
            element: <UserData />,
            errorElement: <ErrorView />
          },
          {
            path: ':participantId',
            element: <UserData />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'survey-data',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin, UserRoles.Analyst]}><SurveyResults /></PrivateRoute>,
        children: [
          {
            index: true,
            element: <SurveyResults />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId',
            element: <SurveyResults />,
            errorElement: <ErrorView />,
            children: [
              {
                path: 'survey/:date',
                element: <SurveyResults />,
                errorElement: <ErrorView />
              }
            ]
          }
        ]
      },
      {
        path: 'study-data',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin, UserRoles.Analyst]}><StudyData /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            index: true,
            element: <StudyData />,
            errorElement: <ErrorView />
          },
          {
            path: ':studyId',
            element: <StudyData />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'messaging',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin, UserRoles.Analyst]}><Messaging /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            index: true,
            element: <Messaging />,
            errorElement: <ErrorView />
          },
          {
            path: ':userId',
            element: <Messaging />,
            errorElement: <ErrorView />
          }
        ]
      },
      {
        path: 'message-templates',
        element: <PrivateRoute restrictedRoles={[UserRoles.Admin]}><MessageTemplates /></PrivateRoute>,
        errorElement: <ErrorView />,
        children: [
          {
            index: true,
            element: <MessageTemplateInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':id',
            element: <MessageTemplateInfo />,
            errorElement: <ErrorView />
          },
          {
            path: ':id/edit',
            element: <MessageTemplateEdit />,
            errorElement: <ErrorView />
          },
          {
            path: 'add',
            element: <MessageTemplateAdd />,
            errorElement: <ErrorView />
          }
        ]
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
