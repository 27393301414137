import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Segment } from 'semantic-ui-react';
import { selectAllParticipants } from '../../../store/reducers/participantsSlice';
import { Participant } from '../../../types/UserData';

export default function ParticipantInfo (): JSX.Element {
  // Get the userId param from the URL.
  const { userId } = useParams();
  const navigate = useNavigate();
  const participants = useSelector(selectAllParticipants);
  const user = participants.find((u: Participant) => u.id === userId);
  let garminId;

  if (userId === undefined || userId === null) {
    return (
      <Segment className="View User-info">
          Select a participant from the list or add a new one
      </Segment>
    );
  }

  if (user == null) {
    return (
      <Segment className="View User-info">
          No user found with this ID
      </Segment>
    );
  }

  const edit = (): void => {
    navigate('edit');
  };

  if (user.deviceIds != null) {
    garminId = user.deviceIds[0];
  }

  return (
    <Segment className="View User-info admin" key={userId}>
        <Form>
            <Form.Field>
                <label>ID:</label>
                <input readOnly={true} placeholder='ID' value={userId} />
            </Form.Field>
            <Form.Field>
                <label>Garmin ID:</label>
                <input readOnly={true} placeholder='Unset' value={garminId} />
            </Form.Field>
        </Form>
        <div className='list-actions'>
        <Button onClick={edit}>Edit</Button>
        </div>
    </Segment>
  );
}
