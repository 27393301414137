import { useEffect } from 'react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchMessageTemplates, selectMessageTemplateStatus } from '../../../store/reducers/messageTemplatesReducer';
import MessageTemplatesList from './MessageTemplatesList';
import { Outlet } from 'react-router-dom';

export default function MessageTemplates (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const status = useSelector(selectMessageTemplateStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle) {
      void dispatch(fetchMessageTemplates());
    }
  }, [status, dispatch]);

  return (
    <div className="Messaging Page row">
        <MessageTemplatesList />
        <Outlet />
    </div>
  );
}
