import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DataBreakdown from './DataBreakdown';
import DataGraphs from './DataGraphs';
import ExtraData from './ExtraData';

export default function DataOverview (): JSX.Element {
  const { participantId } = useParams();
  const [selectedDate, setSelectedDate] = useState<number | undefined>(undefined);

  return (
    <>
      <ExtraData participantId={participantId} />
      <div className="study-data Page row">
          <DataBreakdown participantId={participantId} setSelectedDate={setSelectedDate} selectedDate={selectedDate}/>
          <DataGraphs participantId={participantId} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
      </div>
    </>
  );
}
