import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Form, Message, Segment } from 'semantic-ui-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { selectAllStudies, selectStudiesStatus, addStudy, selectStudiesError } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { StudyInfo } from '../../../types/StudyTypes';
import { isNilOrWhitespace } from '../../../common/utils';
import { Prefix, Prefixes } from '../../../types/UserData';

interface DateRangeSelection {
  startDate?: Date | undefined
  endDate?: Date | undefined
  key?: string | undefined
  showDateDisplay?: boolean | undefined
  autoFocus?: boolean
}

export default function NewStudyInfo (): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const [prefix, setPrefix] = useState<string>(Prefixes.Eradicate);
  const [id, setId] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRangeSelection>(
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      showDateDisplay: true,
      autoFocus: true
    }
  );

  const studies = useSelector((state: RootState) => selectAllStudies(state));
  const studiesLength = useRef(studies.length);
  const status = useSelector(selectStudiesStatus);
  const [error, setError] = useState<string | undefined>(undefined);
  const requestError = useSelector(selectStudiesError);
  const currentStatus = useRef(status);

  const save = (): void => {
    if (name !== undefined && id !== undefined && dateRange.startDate !== undefined && dateRange.endDate !== undefined) {
      const newStudy: StudyInfo = {
        id: prefix + id,
        name,
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
        participants: 0
      };
      void dispatch(addStudy(newStudy));
    }
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate('..');
  };

  useEffect(() => {
    if (studiesLength.current !== studies.length) {
      // User was saved - navigate to user info view
      navigate(`../${studies[studies.length - 1].id}`);
    }
  }, [navigate, studies]);

  useEffect(() => {
    if (currentStatus.current !== status) {
      // If moving to loading status - remove the error
      // If moving from loading to failure - note the error
      if (currentStatus.current !== RequestStatuses.loading && status === RequestStatuses.loading) {
        setError(undefined);
      } else if (currentStatus.current === RequestStatuses.loading && status === RequestStatuses.failed) {
        setError(requestError);
      }
      currentStatus.current = status;
    }
  }, [status]);

  const saveDisabled = isNilOrWhitespace(name) || isNilOrWhitespace(id) || dateRange.startDate === undefined || dateRange.endDate === undefined;

  return (
        <Segment className="View User-info admin">
          <div className='segment-content'>
            <Form >
                <Form.Field >
                    <label data-lpignore='true'>ID:</label>
                    <Form.Group>
                      <Dropdown
                        compact
                        selection
                        options={[{ key: Prefixes.BetaTest, value: Prefixes.BetaTest, text: Prefixes.BetaTest },
                          { key: Prefixes.Eradicate, value: Prefixes.Eradicate, text: Prefixes.Eradicate }]}
                        value={prefix}
                        onChange={(_e: SyntheticEvent, { value }) => {
                          // safety check that the selected value is one of the expected enum types,
                          // Before it is used as that type
                          if (Object.values(Prefixes).includes(value as Prefix)) {
                            setPrefix(value as Prefix);
                          }
                        }}
                      />
                      <input
                        type='text'
                        autoComplete='off'
                        data-lpignore='true'
                        placeholder='Enter ID'
                        onChange={(e) => {
                          setId(e.target.value);
                        }}
                        value={id} />
                    </Form.Group>
                </Form.Field>
                <Form.Field >
                    <label data-lpignore='true'>Name:</label>
                    <Form.Group>
                      <input
                        type='text'
                        autoComplete='off'
                        data-lpignore='true'
                        placeholder='Enter Name'
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name} />
                    </Form.Group>
                </Form.Field>
                <Form.Field >
                    <label data-lpignore='true'>Study Duration:</label>
                    <DateRange
                      className='date-range-input'
                      rangeColors={['#62ddfc']}
                      editableDateInputs={true}
                      onChange={item => {
                        setDateRange(item.selection);
                      }}
                      moveRangeOnFirstSelection={false}
                      preventSnapRefocus={true}
                      ranges={[dateRange]}
                    />
                </Form.Field>
            </Form>
            <Message color='red' hidden={error === undefined}>Error occured. Try again.</Message>
            <div className='list-actions'>
                <Button loading={status === RequestStatuses.loading} onClick={cancel}>Cancel</Button>
                <Button loading={status === RequestStatuses.loading} onClick={save} disabled={saveDisabled}>Save</Button>
            </div>
          </div>
        </Segment>
  );
}
