import { useSelector } from 'react-redux';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { selectParticipantsStatus, selectParticipantData } from '../../../store/reducers/participantsSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import stepsImg from '../../../images/icons8-boot-print-64.png';
import respImg from '../../../images/icons8-breath-100.png';
import oxyImg from '../../../images/icons8-oxygen-saturation-64.png';

interface ExtraDataProps {
  participantId: string | undefined
}

/**
 * Component to render extra participant data
 * @param props ExtraDataProps type
 * @returns react component
 */
export default function ExtraData ({ participantId }: ExtraDataProps): JSX.Element {
  const status = useSelector(selectParticipantsStatus);
  const participantData = useSelector((state: RootState) => selectParticipantData(state, participantId));

  const stepsAvg = participantData?.stepsAvg != null ? participantData.stepsAvg : 'N/A';
  const respAvg = participantData?.respAvg != null ? `${participantData.respAvg}/min` : 'N/A';
  const spo2Avg = participantData?.spo2Avg != null ? `${participantData.spo2Avg}%` : 'N/A';

  const getContent = (): JSX.Element => {
    if (participantId === undefined) {
      return (<>No participant selected</>);
    }

    return (
      <>
        <div className='info'>
          <img src={stepsImg} />
          <span>{stepsAvg}</span>
        </div>
        <div className='info'>
          <img src={respImg} />
          <span>{respAvg}</span>
        </div>
        <div className='info'>
          <img src={oxyImg} />
          <span>{spo2Avg}</span>
        </div>
      </>
    );
  };

  return (
        <Segment className="View study-info extra-info" key="user-extra-info">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <div className='user-extra-info'>
              {getContent()}
            </div>
        </Segment>
  );
}
