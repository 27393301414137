import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Segment } from 'semantic-ui-react';
import { addMessageTemplate, selectMessageTemplateStatus } from '../../../store/reducers/messageTemplatesReducer';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { MessageTemplate } from '../../../types/UserData';

export default function MessageTemplateAdd (): JSX.Element {
  // Get the userId param from the URL.
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const status = useSelector(selectMessageTemplateStatus);
  const current = useRef(status);

  const [message, setMessage] = useState('');

  const save = (): void => {
    const updatedMessage: MessageTemplate = {
      id: new Date().getTime().toString(),
      content: message
    };
    void dispatch(addMessageTemplate(updatedMessage));
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate(-1);
  };

  useEffect(() => {
    if (current.current !== status) {
      // If success from loading - navigate back
      if (status === RequestStatuses.success && current.current === RequestStatuses.loading) {
        navigate(-1);
      }
      current.current = status;
    }
  }, [status]);

  return (
    <Segment className="View User-info admin">
        <Form>
            <Form.Field>
                <label>Message:</label>
                <textarea placeholder='Enter message' value={message} onChange={(e) => {
                  setMessage(e.target.value);
                }}/>
            </Form.Field>
        </Form>
        <div className='list-actions'>
          <Button loading={status === RequestStatuses.loading} onClick={cancel}>Cancel</Button>
          <Button loading={status === RequestStatuses.loading} onClick={save}>Save</Button>
        </div>
    </Segment>
  );
}
