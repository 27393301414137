import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Dimmer, Form, Loader, Segment } from 'semantic-ui-react';
import { login, selectAuthError, selectAuthStatus, selectAuthUser } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import './LoginView.css';

function LoginView (): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);
  let error = useSelector(selectAuthError);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();

  if (searchParams.get('expired') === 'true') {
    error = 'Session expired.';
  }

  const onClick = (): void => {
    void dispatch(login({ username, password }));
  };

  useEffect(() => {
    if (authUser !== undefined) {
      navigate('/study-data');
    }
  }, [authUser]);

  return (
    <div className='Page row'>
        <Segment className="View Login-view">
            <Dimmer active={authStatus === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <Form>
                <Form.Field>
                    <label>Username:</label>
                    <input placeholder='Username'
                      id='username'
                      autoComplete='username'
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}/>
                </Form.Field>
                <Form.Field>
                    <label>Password:</label>
                    <input placeholder='Password' type='password'
                      autoComplete='current-password'
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}/>
                </Form.Field>
                <Button type='submit' onClick={onClick}>Login</Button>
            </Form>
            <>{error}</>
        </Segment>
        </div>
  );
}

export default LoginView;
