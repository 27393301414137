import { useEffect } from 'react';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchParticipants, selectAllParticipants, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import DataOverview from './DataOverview';
import ParticipantsList from './ParticipantsList';
import { isMobile } from 'react-device-detect';
import UserDataMobile from './mobile';

export default function UserData (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const participants = useSelector(selectAllParticipants);
  const status = useSelector(selectParticipantsStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle && participants.length === 0) {
      void dispatch(fetchParticipants());
    }
  }, [status, participants.length, dispatch]);

  if (isMobile) {
    return (
      <UserDataMobile />
    );
  }

  return (
    <div className="study-data Page column">
      <ParticipantsList participants={participants} />
      <DataOverview />
    </div>
  );
}
