import { useSearchParams } from 'react-router-dom';
import { Icon, Button } from 'semantic-ui-react';

export default function DataMenu (): JSX.Element {
  const [searchParams, setSearchParams]: [URLSearchParams, Function] = useSearchParams();
  const view = searchParams.get('view');

  const switchMenu = (newView: string): void => {
    searchParams.set('view', newView);
    setSearchParams(searchParams);
  };

  return (
    <div className="data-menu">
        <Button icon onClick={() => switchMenu('search')} active={view === null || view === 'search'}>
            <Icon name='search' />
        </Button>
        <Button icon onClick={() => switchMenu('data')} active={view === 'data'}>
            <Icon name='table' />
        </Button>
        <Button icon onClick={() => switchMenu('graph')} active={view === 'graph'}>
            <Icon name='line graph' />
        </Button>
    </div>
  );
}
