import { createSlice, createAsyncThunk, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { StudyData, StudyInfo, StudyUsersEdit } from '../../types/StudyTypes';
import { RootState } from '../store';

// Define a type for the slice state
interface StudyIdToData {
  [key: string]: StudyData[]
}

interface StudyIdToUsers {
  [key: string]: string[]
}

interface StudiesState {
  studies: StudyInfo[]
  status: RequestStatus
  error: string | undefined
  data: StudyIdToData
  studyUsers: StudyIdToUsers
}

interface StudiesUsersPostArgs {
  studyId: string
  studyUsersEdit: StudyUsersEdit
}

const initialState: StudiesState = {
  studies: [],
  status: RequestStatuses.idle,
  error: undefined,
  data: {},
  studyUsers: {}
};

export const fetchStudies = createAsyncThunk('studies/fetchStudies', async (arg: undefined, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.get('/api/studies', {}, authToken);
  return response.data;
});

export const addStudy = createAsyncThunk('studies/add', async (study: StudyInfo, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.post('/api/studies', study, {}, authToken);
  return response.data;
});

export const updateStudy = createAsyncThunk('studies/update', async (study: StudyInfo, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.put('/api/studies', study, {}, authToken);
  return response.data;
});

export const fetchStudyUsers = createAsyncThunk(
  'studies/fetchStudyUsers',
  async (studyId: string, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.get(`/api/studies/${studyId}/users`, {}, authToken);
    return response.data;
  }
);

export const putStudyUsers = createAsyncThunk(
  'studies/putStudyUsers',
  async (studyUsers: StudiesUsersPostArgs, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.put(`/api/studies/${studyUsers.studyId}/users`, studyUsers.studyUsersEdit, {}, authToken);
    return response.data;
  }
);

export const fetchStudyData = createAsyncThunk(
  'studies/fetchStudyData',
  async (studyId: string, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.get('/api/studies/' + studyId, {}, authToken);
    return response.data;
  }
);

const studiesSlice = createSlice({
  name: 'studies',
  initialState,
  reducers: {

  },
  extraReducers (builder: ActionReducerMapBuilder<StudiesState>) {
    builder
      .addCase(fetchStudies.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchStudies.fulfilled, (state: StudiesState, action: PayloadAction<StudyInfo[]>) => {
        state.status = RequestStatuses.success;
        state.studies = action.payload;
        state.error = undefined;
      })
      .addCase(fetchStudies.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(fetchStudyData.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchStudyData.fulfilled, (state: StudiesState, action: PayloadAction<StudyData[], any, { arg: string }>) => {
        state.status = RequestStatuses.success;
        state.data[action.meta.arg] = action.payload;
        state.error = undefined;
      })
      .addCase(fetchStudyData.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(addStudy.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(addStudy.fulfilled, (state: StudiesState, action: PayloadAction<any, any, { arg: StudyInfo }>) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
        state.studies.push(action.meta.arg);
        state.studyUsers[action.meta.arg.id] = [];
      })
      .addCase(addStudy.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(updateStudy.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(updateStudy.fulfilled, (state: StudiesState) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
      })
      .addCase(updateStudy.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(putStudyUsers.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(putStudyUsers.fulfilled, (state: StudiesState, action: PayloadAction<any, any, { arg: StudiesUsersPostArgs }>) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
        const existingStudyUsers = state.studyUsers[action.meta.arg.studyId];
        let editedStudyUsers = existingStudyUsers.concat(action.meta.arg.studyUsersEdit.usersToAdd);
        editedStudyUsers = editedStudyUsers.filter(o => !action.meta.arg.studyUsersEdit.usersToRemove.includes(o));
        state.studyUsers[action.meta.arg.studyId] = editedStudyUsers;

        // Find the study and amend its participants number
        const study = state.studies.find(s => s.id === action.meta.arg.studyId);
        if (study?.participants != null) {
          study.participants = study.participants + action.meta.arg.studyUsersEdit.usersToAdd.length;
          study.participants = study.participants - action.meta.arg.studyUsersEdit.usersToRemove.length;
        }
      })
      .addCase(putStudyUsers.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(fetchStudyUsers.pending, (state: StudiesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchStudyUsers.fulfilled, (state: StudiesState, action: PayloadAction<string[], any, { arg: string }>) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
        state.studyUsers[action.meta.arg] = action.payload;
      })
      .addCase(fetchStudyUsers.rejected, (state: StudiesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      });
  }
});

export default studiesSlice.reducer;

export const selectStudiesStatus = (state: RootState): RequestStatus => state.studiesStore.status;
export const selectStudiesError = (state: RootState): string | undefined => state.studiesStore.error;
export const selectAllStudies = (state: RootState): StudyInfo[] => state.studiesStore.studies;
export const selectAllStudiesIDs = (state: RootState): string[] => state.studiesStore.studies.map(s => s.id);
export const selectStudyById = (state: RootState, studyId: string | undefined): StudyInfo | undefined => {
  return state.studiesStore.studies.find((study: StudyInfo) => study.id === studyId);
};
export const selectStudyUsers = (state: RootState, studyId: string | undefined): string[] | undefined => {
  if (studyId !== undefined) {
    return state.studiesStore.studyUsers[studyId];
  }
  return undefined;
};

export const selectStudyDataById = (state: RootState, studyId: string | undefined): StudyData[] | undefined => {
  if (studyId !== undefined) {
    return state.studiesStore.data[studyId];
  }
  return undefined;
};
