import { useRouteError } from 'react-router-dom';

export default function ErrorView (): JSX.Element {
  const error: any = useRouteError();
  console.error(error);
  let errorMessage = error.message;
  if (error.statusText !== undefined && error.statusText !== null) {
    errorMessage = error.statusText;
  }

  return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{errorMessage}</i>
            </p>
        </div>
  );
}
