import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import StudyList from './StudyList';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchStudies, selectAllStudies, selectStudiesStatus } from '../../../store/reducers/studiesSlice';
import { fetchParticipants, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';

export default function StudyManagement (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const studies = useSelector(selectAllStudies);
  const status = useSelector(selectStudiesStatus);
  const usersStatus = useSelector(selectParticipantsStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle) {
      void dispatch(fetchStudies());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (usersStatus === RequestStatuses.idle) {
      void dispatch(fetchParticipants());
    }
  }, [usersStatus, dispatch]);

  return (
    <div className="User-management Page row">
      <StudyList studies={studies} />
      <Outlet />
    </div>
  );
}
