import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dimmer, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { RequestStatuses } from '../../../types/status';
import { selectAllMessageTemplates, selectMessageTemplateStatus } from '../../../store/reducers/messageTemplatesReducer';

export default function MessageTemplatesList (): JSX.Element {
  const navigate = useNavigate();

  // Get the userId param from the URL.
  const { id } = useParams();
  const messages = useSelector(selectAllMessageTemplates);
  const status = useSelector(selectMessageTemplateStatus);

  const onChange = (id: string): void => {
    navigate({ pathname: id });
  };

  const add = (): void => {
    navigate('add');
  };

  const getMessagesTable = (): JSX.Element => {
    const tableRows = [];
    if (messages === undefined || messages.length === 0) {
      tableRows.push(<Table.Row key='row-empty'><Table.Cell>No messages</Table.Cell></Table.Row>);
    } else {
      messages.forEach((m, i) => {
        tableRows.push(<Table.Row key={`row-meesage-${i}`} active={m.id === id} onClick={() => onChange(m.id)}>
          <Table.Cell>{m.content}</Table.Cell>
      </Table.Row>);
      }
      );
    }
    return (
        <Table celled unstackable>
            <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tableRows}
            </Table.Body>
        </Table>
    );
  };

  return (
    <Segment className="View">
      <Dimmer active={status === RequestStatuses.loading}>
        <Loader active={true} />
      </Dimmer>
      <Header size='medium'>Available Participant Messages</Header>
        {getMessagesTable()}
        <div className="list-actions">
            <Button icon onClick={add}><Icon name='plus' /></Button>
        </div>
    </Segment>
  );
}
