import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Segment } from 'semantic-ui-react';
import { selectMessageTemplateById } from '../../../store/reducers/messageTemplatesReducer';
import { RootState } from '../../../store/store';

export default function MessageTemplateInfo (): JSX.Element {
  // Get the userId param from the URL.
  const { id } = useParams();
  const navigate = useNavigate();
  const messageTemplate = useSelector((state: RootState) => selectMessageTemplateById(state, id));

  if (messageTemplate == null) {
    return (
      <Segment className="View User-info">
          Select a message template from the list or add a new one
      </Segment>
    );
  }

  const edit = (): void => {
    navigate('edit');
  };

  return (
    <Segment className="View User-info admin" key={id}>
        <Form>
            <Form.Field>
                <label>Message:</label>
                <textarea readOnly={true} value={messageTemplate.content} />
            </Form.Field>
        </Form>
        <div className='list-actions'>
        <Button onClick={edit}>Edit</Button>
        </div>
    </Segment>
  );
}
