import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, List, Loader, Message, Modal } from 'semantic-ui-react';
import { selectAllParticipantsIDs } from '../../../store/reducers/participantsSlice';
import { putStudyUsers, selectStudiesError, selectStudiesStatus, selectStudyUsers } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';

interface StudyUsersModalProps {
  studyId: string
  open: boolean
  setOpen: Function
}

export default function StudyUsersModal (props: StudyUsersModalProps): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const status = useSelector(selectStudiesStatus);
  const studyUsers = useSelector((state: RootState) => selectStudyUsers(state, props.studyId));
  const allUsers = useSelector(selectAllParticipantsIDs).filter(u => u.slice(0, 2) === props.studyId.slice(0, 2));
  const [error, setError] = useState<string | undefined>(undefined);
  const requestError = useSelector(selectStudiesError);
  const currentStatus = useRef(status);
  const [allStudyUsers, setAllStudyUsers] = useState<string[]>([]);

  useEffect(() => {
    if (studyUsers !== undefined) {
      console.log('Reset the all study users');
      setAllStudyUsers(studyUsers);
    }
  }, [studyUsers]);

  useEffect(() => {
    if (currentStatus.current !== status) {
      // If moving to loading status - remove the error
      // If moving from loading to failure - note the error
      if (status === RequestStatuses.success && props.open) {
        props.setOpen(false);
      } else if (currentStatus.current !== RequestStatuses.loading && status === RequestStatuses.loading) {
        setError(undefined);
      } else if (currentStatus.current === RequestStatuses.loading && status === RequestStatuses.failed) {
        setError(requestError);
      }
      currentStatus.current = status;
    }
  }, [status]);

  const onClose = (): void => {
    // On closing of the modal, reset the changes
    setAllStudyUsers(studyUsers !== undefined ? studyUsers : []);
    props.setOpen(false);
  };

  const save = (): void => {
    const usersToAdd = allStudyUsers.filter(o => studyUsers === undefined || !studyUsers.includes(o));
    const usersToRemove = studyUsers === undefined ? [] : studyUsers.filter(o => !allStudyUsers.includes(o));
    void dispatch(putStudyUsers({ studyId: props.studyId, studyUsersEdit: { usersToAdd, usersToRemove } }));
  };

  const getAllUsersView = (): JSX.Element => {
    return (
      <div className='user-selection'>
        <div>All Users</div>
        <List className='user-selection'>
          {
            allUsers.slice().sort().filter(u => !allStudyUsers.includes(u)).map(u =>
              <List.Item key={u} onClick={() => setAllStudyUsers([...allStudyUsers, u])}>{u}</List.Item>
            )
          }
        </List>
      </div>
    );
  };

  const getAllStudyUsersView = (): JSX.Element => {
    return (
      <div className='user-selection'>
        <div>Users in the study</div>
        <List className='user-selection'>
          {
            allStudyUsers.slice().sort().map(u =>
              <List.Item key={u} onClick={() => setAllStudyUsers(allStudyUsers.filter(su => su !== u))}>{u}</List.Item>
            )
          }
        </List>
      </div>
    );
  };

  return (
    <Modal
      onOpen={() => props.setOpen(true)}
      open={props.open}
      className="hrv-modal study-users"
    >
      <Dimmer active={status === RequestStatuses.loading}>
          <Loader active={true} />
      </Dimmer>
      <Modal.Header>Select users for {props.studyId} study</Modal.Header>
      <Modal.Content>
        <div className='flex row overflow'>
          {getAllUsersView()}
          {getAllStudyUsersView()}
        </div>
        <Message color='red' hidden={error === undefined}>Error occured. Try again.</Message>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={onClose}>
          Cancel
        </Button>
        <Button
          content="Save"
          onClick={save}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
