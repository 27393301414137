import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ParticipantList from './ParticipantList';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchParticipants, selectAllParticipants, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';

export default function ParticipantManagement (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const users = useSelector(selectAllParticipants);
  const status = useSelector(selectParticipantsStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle) {
      void dispatch(fetchParticipants());
    }
  }, [status, dispatch]);

  return (
    <div className="User-management Page row">
      <ParticipantList users={users} />
      <Outlet />
    </div>
  );
}
