import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import UserList from './UserList';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, selectAllUsers, selectUsersStatus } from '../../../store/reducers/usersSlice';
import { RequestStatuses } from '../../../types/status';

export default function UserManagement (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const users = useSelector(selectAllUsers);
  const usersStatus = useSelector(selectUsersStatus);

  useEffect(() => {
    if (usersStatus === RequestStatuses.idle) {
      void dispatch(fetchUsers());
    }
  }, [usersStatus, dispatch]);

  return (
        <div className="User-management Page row">
            <UserList users={users} />
            <Outlet />
        </div>
  );
}
