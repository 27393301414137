import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Dimmer, Dropdown, Form, Loader, Segment } from 'semantic-ui-react';
import { fetchStudyData, selectStudiesStatus, selectStudyById } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { StudyInfo } from '../../../types/StudyTypes';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

interface StudiesProps {
  studies: StudyInfo[]
}

export default function StudyList ({ studies }: StudiesProps): JSX.Element {
  const { studyId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const status = useSelector(selectStudiesStatus);
  const study = useSelector((state: RootState) => selectStudyById(state, studyId));

  const [selectedId, setSelectedId] = useState<string | undefined>(studyId);

  useEffect(() => {
    setSelectedId(studyId);
  }, [studyId, setSelectedId]);

  const onChange = (id: string): void => {
    setSelectedId(id);
    navigate('' + id);
  };

  const reloadData = (): void => {
    if (studyId != null) {
      void dispatch(fetchStudyData(studyId));
    }
  };

  const getStudyInfo = (): JSX.Element => {
    if (study != null) {
      return (
        <>
          <Form.Field>
              <label>Participants</label>
              <input readOnly placeholder='No. of Participants' value={study.participants} />
          </Form.Field>
          <Form.Group>
            <Form.Field>
                <label>Start Date</label>
                <input readOnly placeholder='Start Date' value={new Date(study.startDate).toDateString()} />
            </Form.Field>
            <Form.Field>
                <label>End Date</label>
                <input readOnly placeholder='End Date' value={new Date(study.endDate).toDateString()} />
            </Form.Field>
          </Form.Group>
        </>
      );
    }
    return <></>;
  };

  return (
        <Segment className="View study-info" key="study-info">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <Form>
                <Form.Field>
                    <label>Study:</label>
                    <div >
                      <Dropdown
                          placeholder='Select study'
                          selection
                          search
                          options={studies.slice(0).sort((s1, s2) => new Date(s2.startDate).getTime() - new Date(s1.startDate).getTime()).map(s => ({
                            key: s.id,
                            value: s.id,
                            text: s.name
                          }))}
                          value={selectedId}
                          onChange={(e, { value }) => {
                            onChange(value as string);
                          }
                          }
                      />
                      <Button icon='refresh' disabled={studyId == null} onClick={reloadData}/>
                    </div>
                </Form.Field>
                {getStudyInfo()}
            </Form>
        </Segment>
  );
}
