import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Message, Segment } from 'semantic-ui-react';
import { selectParticipantById, selectParticipantsError, selectParticipantsStatus, updateParticipant } from '../../../store/reducers/participantsSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { Participant } from '../../../types/UserData';

export default function EditParticipantInfo (): JSX.Element {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const participantToEdit = useSelector((state: RootState) => selectParticipantById(state, userId));

  if (participantToEdit == null) {
    return (
      <Segment className="View User-info">
          No user found with this ID
      </Segment>
    );
  }

  const [id] = useState<string>(participantToEdit.id);
  const [error, setError] = useState<string | undefined>(undefined);
  const [garminId, setGarminId] = useState<string | undefined>(participantToEdit.deviceIds != null ? participantToEdit.deviceIds[0] : undefined);
  const status = useSelector(selectParticipantsStatus);
  const requestError = useSelector(selectParticipantsError);
  const currentStatus = useRef(status);

  const save = (): void => {
    const updatedParticipant: Participant = {
      id,
      deviceIds: null
    };
    if (garminId != null) {
      updatedParticipant.deviceIds = [garminId];
    }
    void dispatch(updateParticipant(updatedParticipant));
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate(-1);
  };

  useEffect(() => {
    if (currentStatus.current !== status) {
      // If moving to loading status - remove the error
      // If moving from loading to failure - note the error
      // If moving to success - navigate back
      if (currentStatus.current !== RequestStatuses.loading && status === RequestStatuses.loading) {
        setError(undefined);
      } else if (currentStatus.current === RequestStatuses.loading && status === RequestStatuses.failed) {
        setError(requestError);
      } else if (status === RequestStatuses.success && currentStatus.current === RequestStatuses.loading) {
        navigate(-1);
      }
      currentStatus.current = status;
    }
  }, [status]);

  return (
    <Segment className="View User-info admin">
      <Form >
        <Form.Field >
          <label data-lpignore='true'>ID:</label>
          <input
            readOnly
            type='text'
            value={id} />
        </Form.Field>
        <Form.Field >
          <label data-lpignore='true'>Garmin ID:</label>
          <input
            type='text'
            autoComplete='off'
            data-lpignore='true'
            onChange={(e) => {
              setGarminId(e.target.value);
            }}
            value={garminId} />
        </Form.Field>
      </Form>
      <Message color='red' hidden={error === undefined}>Error occured. Try again.</Message>
      <div className='list-actions'>
        <Button loading={status === RequestStatuses.loading} onClick={cancel}>Cancel</Button>
        <Button loading={status === RequestStatuses.loading} onClick={save}>Save</Button>
      </div>
    </Segment>
  );
}
