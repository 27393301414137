import React, { useEffect, useRef } from 'react';
import './style.css';
import { Icon, List, Dropdown, Popup } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { UserRoles } from '../../types/UserData';
import { logout } from '../../store/reducers/userReducer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';

interface MenuProps {
  open: boolean
  setMenuOpen: Function
}

function SidebarMenu (props: MenuProps): JSX.Element {
  const { user } = useSelector((state: RootState) => state.userStore);

  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const componentRef = useRef<HTMLDivElement>(null);
  const open = props.open;

  if (isMobile) {
    const onClickOut = (isOpen: boolean): void => {
      props.setMenuOpen((prev: boolean) => {
        if (prev) {
          return !prev;
        }
        return prev;
      });
    };

    useEffect(() => {
      const onClick = (event: any): any => {
        // If the menu is not closed (ie width != 0) and user clicked somewhere outside - close the menu
        if (componentRef.current !== null && componentRef.current.offsetWidth !== 0 && componentRef.current.offsetWidth < event.x) {
          onClickOut?.(open);
        }
      };
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }, []);
  }

  const logOut = (): void => {
    void dispatch(logout());
    navigate('/');
  };

  const getContent = (): JSX.Element => {
    if (user != null) {
      if (user.role === UserRoles.Admin) {
        return (<>
                  <List.Item className="a-not-implemented">
                      <Dropdown item icon='users'>
                          <Dropdown.Menu className="submenu">
                              <Dropdown.Item >
                                  <Link to={'admin-management'}>
                                      <Popup content='Admin Management' position='right center' trigger={<Icon name='user' />} />
                                  </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to={'participant-management'}>
                                  <Popup content='Participant Management' position='right center' trigger={<Icon name='user outline' />} />
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to={'study-management'}>
                                  <Popup content='Study Management' position='right center' trigger={<Icon name='calendar alternate outline' />} />
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to={'change-password'}>
                                  <Popup content='Change Password' position='right center' trigger={<Icon name='key' />} />
                                </Link>
                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  </List.Item>
                  <List.Item>
                      <Dropdown item icon='line graph'>
                          <Dropdown.Menu className="submenu">
                              <Dropdown.Item>
                                  <Link to={'user-data'}>
                                      <Popup className={isMobile ? 'not-implemented' : ''} content='User Data' position='right center' trigger={<Icon name='user outline' />} />
                                  </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                  <Link to={'study-data'}>
                                      <Popup className={isMobile ? 'not-implemented' : ''} content='Study Data' position='right center' trigger={<Icon name='calendar alternate outline' />} />
                                  </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                  <Link to={'survey-data'}>
                                      <Popup className={isMobile ? 'not-implemented' : ''} content='Survey Data' position='right center' trigger={<Icon name='file alternate outline' />} />
                                  </Link>
                              </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>
                  </List.Item>
                  <List.Item>
                      <Link to={'messaging'}>
                          <Popup content='Messaging' position='right center' trigger={<Icon name='mail outline' />} />
                      </Link>
                  </List.Item>
                  <List.Item>
                      <Link to={'message-templates'}>
                          <Popup content='Available Messages' position='right center' trigger={<Icon name='mail' />} />
                      </Link>
                  </List.Item>
                  <List.Item onClick={logOut}>
                      <Popup className={isMobile ? 'not-implemented' : ''} content='Log Out' position='right center' trigger={<Icon name='power off' />} />
                  </List.Item>
                </>
        );
      } else if (user.role === UserRoles.Analyst) {
        return (
          <>
            <List.Item>
                <Dropdown item icon='users'>
                    <Dropdown.Menu className="submenu">
                      <Link to={'change-password'}>
                        <Popup content='Change Password' position='right center' trigger={<Icon name='key' />} />
                      </Link>
                    </Dropdown.Menu>
                </Dropdown>
            </List.Item>
            <List.Item>
              <Dropdown item icon='line graph'>
                <Dropdown.Menu className="submenu">
                  <Dropdown.Item>
                    <Link to={'user-data'}>
                      <Popup className={isMobile ? 'not-implemented' : ''} content='User Data' position='right center' trigger={<Icon name='user outline' />} />
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={'study-data'}>
                      <Popup className={isMobile ? 'not-implemented' : ''} content='Study Data' position='right center' trigger={<Icon name='calendar alternate outline' />} />
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={'survey-data'}>
                      <Popup className={isMobile ? 'not-implemented' : ''} content='Survey Data' position='right center' trigger={<Icon name='file alternate outline' />} />
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </List.Item>
            <List.Item onClick={logOut}>
                <Popup className={isMobile ? 'not-implemented' : ''} content='Log Out' position='right center' trigger={<Icon name='power off' />} />
            </List.Item>
          </>
        );
      }
    }

    return (
            <List.Item>
                <Link to={'login'}>
                    <Icon name='sign-in' />
                </Link>
            </List.Item>
    );
  };

  return (
    <div className={'Sidebar-menu ' + (props.open ? 'open' : 'closed')} ref={componentRef}>
        <List >
            {getContent()}
        </List>
    </div>
  );
}

export default SidebarMenu;
