import { createSlice, createAsyncThunk, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { MessageTemplate } from '../../types/UserData';
import { RootState } from '../store';

// Define a type for the slice state
interface MessageTemplatesState {
  messageTemplates: MessageTemplate[]
  status: RequestStatus
  error: string | undefined
}

const initialState: MessageTemplatesState = {
  messageTemplates: [],
  status: RequestStatuses.idle,
  error: undefined
};

export const fetchMessageTemplates = createAsyncThunk('messageTemplates/fetchMessageTemplates', async (arg: undefined, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.get('/api/message-templates', {}, authToken);
  return response.data;
});

export const addMessageTemplate = createAsyncThunk(
  'posts/addMessageTemplate',
  async (messageTemplate: MessageTemplate, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post('/api/message-templates', messageTemplate, {}, authToken);
    return response.data;
  }
);

export const updateMessageTemplate = createAsyncThunk(
  'posts/updateMessageTemplate',
  async (messageTemplate: MessageTemplate, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.put('/api/message-templates', messageTemplate, {}, authToken);
    return response.data;
  }
);

export const deleteMessageTemplate = createAsyncThunk(
  'posts/deleteMessageTemplate',
  async (id: string, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.delete('/api/message-templates/' + id, {}, authToken);
    return response.data;
  }
);

const messageTemplatesSlice = createSlice({
  name: 'messageTemplates',
  initialState,
  reducers: {

  },
  extraReducers (builder: ActionReducerMapBuilder<MessageTemplatesState>) {
    builder
      .addCase(fetchMessageTemplates.pending, (state: MessageTemplatesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchMessageTemplates.fulfilled, (state: MessageTemplatesState, action: PayloadAction<MessageTemplate[]>) => {
        state.status = RequestStatuses.success;
        // Add any fetched posts to the array
        state.messageTemplates = action.payload;
      })
      .addCase(fetchMessageTemplates.rejected, (state: MessageTemplatesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(addMessageTemplate.pending, (state: MessageTemplatesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(addMessageTemplate.fulfilled, (state: MessageTemplatesState, action: PayloadAction<any, any, { arg: MessageTemplate }>) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
        state.messageTemplates.push(action.meta.arg);
      })
      .addCase(addMessageTemplate.rejected, (state: MessageTemplatesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(updateMessageTemplate.pending, (state: MessageTemplatesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(updateMessageTemplate.fulfilled, (state: MessageTemplatesState, action: PayloadAction<any, any, { arg: MessageTemplate }>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        state.error = undefined;
        const updatedMessage = action.meta.arg;
        const indexToUpdate = state.messageTemplates.findIndex(u => u.id === updatedMessage.id);
        if (indexToUpdate > -1) {
          state.messageTemplates[indexToUpdate] = updatedMessage;
        }
      })
      .addCase(updateMessageTemplate.rejected, (state: MessageTemplatesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(deleteMessageTemplate.pending, (state: MessageTemplatesState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(deleteMessageTemplate.fulfilled, (state: MessageTemplatesState, action: PayloadAction<any, any, any>) => {
        state.status = RequestStatuses.success;
        state.error = undefined;
        // deleted id was used as an argument to request
        state.messageTemplates = state.messageTemplates.filter(u => u.id !== action.meta.arg);
      })
      .addCase(deleteMessageTemplate.rejected, (state: MessageTemplatesState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      });
  }
});

export default messageTemplatesSlice.reducer;

export const selectMessageTemplateStatus = (state: RootState): RequestStatus => state.messageTemplatesStore.status;
export const selectAllMessageTemplates = (state: RootState): MessageTemplate[] => state.messageTemplatesStore.messageTemplates;

export const selectMessageTemplateById = (state: RootState, id: string | undefined): MessageTemplate | undefined =>
  state.messageTemplatesStore.messageTemplates.find((mt: MessageTemplate) => mt.id === id);
