import SurveyDisplay from './SurveyDisplay';
import SurveyResponsePicker from './SurveyResponsePicker';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { fetchParticipants, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import { RequestStatuses } from '../../../types/status';
import { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export default function SurveyResults (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const status = useSelector(selectParticipantsStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle) {
      void dispatch(fetchParticipants());
    }
  }, [status, dispatch]);

  return (
    <div className="study-data Page row">
        <Dimmer active={status === RequestStatuses.loading}>
            <Loader active={true} />
        </Dimmer>
        <SurveyResponsePicker />
        <SurveyDisplay />
    </div>
  );
}
