import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Dimmer, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { fetchStudyUsers, selectStudiesStatus, selectStudyUsers } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';

interface UserListProps {
  setOpen: Function
}

export default function UserList (props: UserListProps): JSX.Element {
  const { studyId } = useParams();
  const studyUsers = useSelector((state: RootState) => selectStudyUsers(state, studyId));

  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const status = useSelector(selectStudiesStatus);

  useEffect(() => {
    if (studyUsers === undefined && studyId !== undefined) {
      void dispatch(fetchStudyUsers(studyId));
    }
  }, [studyUsers, dispatch]);

  const getUserView = (): JSX.Element => {
    if (studyUsers === undefined || studyUsers.length === 0) {
      return (
        <List selection verticalAlign='middle' size='big'>
          <div>Click Add to edit participants</div>
        </List>);
    }
    return (
      <List selection verticalAlign='middle' size='big'>
        {
          studyUsers.slice(0).sort().map(u =>
            <List.Item key={u} >
              <List.Content>
                <List.Header>{u}</List.Header>
              </List.Content>
            </List.Item>
          )
        }
      </List>
    );
  };

  return (
        <Segment className="View User-list" key="user-list">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <div>Study users</div>
            {getUserView()}
            <div className="list-actions">
                <Button icon onClick={() => props.setOpen(true)}><Icon name='plus' /></Button>
            </div>
        </Segment>
  );
}
