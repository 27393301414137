import { useEffect } from 'react';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../../types/status';
import { fetchParticipants, selectAllParticipants, selectParticipantsStatus } from '../../../../store/reducers/participantsSlice';
import ParticipantsList from '../ParticipantsList';
import DataMenu from './DataMenu';
import { useParams } from 'react-router';
import ExtraData from '../ExtraData';
import DataBreakdown from '../DataBreakdown';
import DataGraphs from '../DataGraphs';
import { useSearchParams } from 'react-router-dom';

export default function UserDataMobile (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const participants = useSelector(selectAllParticipants);
  const status = useSelector(selectParticipantsStatus);

  const { participantId } = useParams();
  const [searchParams]: [URLSearchParams, Function] = useSearchParams();

  const getView = (): JSX.Element => {
    if (searchParams.get('view') === 'search') {
      return (
            <ParticipantsList participants={participants} />
      );
    } else if (searchParams.get('view') === 'data') {
      return (
        <>
          <ExtraData participantId={participantId}/>
          <DataBreakdown participantId={participantId} selectedDate={undefined} setSelectedDate={() => null} />
        </>
      );
    } else if (searchParams.get('view') === 'graph') {
      return (
        <>
          <ExtraData participantId={participantId}/>
          <DataGraphs participantId={participantId} selectedDate={undefined} setSelectedDate={() => null} />
        </>
      );
    }
    return (
        <ParticipantsList participants={participants} />
    );
  };

  useEffect(() => {
    if (status === RequestStatuses.idle && participants.length === 0) {
      void dispatch(fetchParticipants());
    }
  }, [status, participants.length, dispatch]);

  return (
    <div className="user-data Page column">
      {getView()}
      <DataMenu/>
    </div>
  );
}
