import { Segment, Table } from 'semantic-ui-react';
import { StudyData } from '../../../types/StudyTypes';

interface DataTableProps {
  data: StudyData
}

export default function DataTable ({ data }: DataTableProps): JSX.Element {
  return (
        <Segment className='data-table'>
            <div>{data.title}</div>
            <Table celled unstackable>
                <Table.Header>
                    <Table.Row>
                        {data.headers.map(h =>
                            <Table.HeaderCell key={h} className={h}>{h}</Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data.data.map((dd, i) =>
                            <Table.Row key={i} >
                                {dd.map((d, i) => <Table.Cell key={i} >
                                    {
                                    data.headers[i] === 'Date'
                                      ? new Date(d).toLocaleString()
                                      : d
                                    }
                                </Table.Cell>)}
                            </Table.Row>

                        )
                    }
                </Table.Body>
            </Table>
        </Segment>
  );
}
