import './App.css';
import { Outlet, useLocation } from 'react-router-dom';

import Header from './common/header';
import SidebarMenu from './common/menu';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';

function App (): JSX.Element {
  const [menuOpen, setMenuOpen] = useState(!isMobile);
  const loca = useLocation();

  if (isMobile) {
    useEffect(() => {
      setMenuOpen(false);
    }, [loca]);
  }

  return (
    <div className={'App ' + (isMobile ? 'mobile' : '') }>
      <Header setMenuOpen={setMenuOpen} />
      <div className='body'>
        <SidebarMenu open={menuOpen} setMenuOpen={setMenuOpen}/>
        <div className='outlet'>
        <Outlet />
      </div>
      </div>
    </div>
  );
}

export default App;
