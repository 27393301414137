import { Segment } from 'semantic-ui-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';

export interface GraphData {
  date: number
  value: string
}

interface GraphProps {
  header: string
  data: GraphData[]
  selectDate: Function
  selectedDate: string | null
  startDate: string | null
  endDate: string | null
}

export default function Graph (props: GraphProps): JSX.Element {
  let selectedX;
  if (props.selectedDate !== null) {
    selectedX = new Date(props.selectedDate).getTime();
  }

  const header = props.header;

  const domainX = [props.data[0].date, props.data[props.data.length - 1].date];

  // Calculate the average, ignoring the null values
  let totalNonNullValues = 0;
  let total = 0;

  props.data.forEach(d => {
    if (d.value !== null) {
      total += parseInt(d.value);
      totalNonNullValues++;
    }
  });

  const average = Math.round(total / totalNonNullValues);
  const allYValues = props.data.map(d => parseInt(d.value)).filter(v => !isNaN(v));
  const domainY = [Math.min(...allYValues), average, Math.max(...allYValues)];

  return (
    <Segment className="View study-data graph">
      <span>{header}</span>
      <ResponsiveContainer width={'99%'} height={'100%'} debounce={1}>
        <LineChart
          data={props.data}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 0
          }}
          onClick={(e) => {
            if (e.activeLabel !== undefined) {
              const s = new Date(e.activeLabel).toISOString();
              props.selectDate(s);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            itemStyle={{ color: 'black' }}
            labelFormatter={(l) => new Date(l).toLocaleString()}
            formatter={(v) => [v as string, header]}
          />
          <XAxis dataKey="date" type="number"
            padding={{ left: 0 }}
            domain={domainX}
            tickFormatter={ (e) => {
              const date = new Date(e);
              return `${date.getDate()}/${(date.getMonth() + 1)} ${date.getHours()}:${date.getMinutes()}`;
            }}
            axisLine={false}
            tickLine={false}
            tickCount = {2}
            ticks={domainX}
            />
          <YAxis dataKey="value" type="number"
            domain={['dataMin', 'dataMax']}
            width={20}
            axisLine={false}
            tickLine={false}
            ticks={domainY} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#62ddfc"
            strokeWidth={2}
            dot={{ r: 0 }}
            activeDot={{ r: 2 }}
          />
          <ReferenceLine x={selectedX} stroke="#8884d8" strokeWidth={3} />
          <ReferenceLine y={average} stroke="#00AA00" strokeWidth={3} label={{ value: 'Average', position: 'insideTopLeft' }}/>
        </LineChart>
        </ResponsiveContainer>
    </Segment>
  );
}
