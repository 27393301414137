import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Dimmer, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { selectStudiesStatus } from '../../../store/reducers/studiesSlice';
import { RequestStatuses } from '../../../types/status';
import { StudyInfo } from '../../../types/StudyTypes';

interface UsersProps {
  studies: StudyInfo[]
}

export default function StudyList ({ studies }: UsersProps): JSX.Element {
  const { studyId } = useParams();

  const navigate = useNavigate();

  const status = useSelector(selectStudiesStatus);

  const [selectedId, setSelectedId] = useState<string | undefined>(studyId);

  // When the user selection (userId) changes - reset the password value shown
  useEffect(() => {
    setSelectedId(studyId);
  }, [studyId, setSelectedId]);

  const onClick = (id: string): void => {
    setSelectedId(id);
    navigate('' + id);
  };

  const add = (): void => {
    setSelectedId(undefined);
    navigate('add');
  };

  return (
    <Segment className="View User-list" key="user-list">
      <Dimmer active={status === RequestStatuses.loading}>
        <Loader active={true} />
      </Dimmer>
      <List selection verticalAlign='middle' size='big'>
        {
          studies.slice(0).sort((s1, s2) => new Date(s2.startDate).getTime() - new Date(s1.startDate).getTime()).map(u =>
            <List.Item key={u.id} onClick={() => onClick(u.id)} active={selectedId === u.id}>
              <List.Content>
                <List.Header>{u.name}</List.Header>
                <List.Content>
                  <div>{new Date(u.startDate).toLocaleDateString() + ' - ' + new Date(u.endDate).toLocaleDateString()}</div>
                </List.Content>
              </List.Content>
            </List.Item>
          )
        }
      </List>
      <div className="list-actions">
        <Button icon onClick={add}><Icon name='plus' /></Button>
      </div>
    </Segment>
  );
}
