import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Dimmer, Dropdown, Form, Loader, Segment } from 'semantic-ui-react';
import { selectAllParticipantsIDs, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import { RequestStatuses } from '../../../types/status';
import SurveyCalendar from './SurveyCalendar';

export default function SurveyResponsePicker (): JSX.Element {
  const { userId } = useParams();

  const navigate = useNavigate();

  const status = useSelector(selectParticipantsStatus);
  const participantIds = useSelector(selectAllParticipantsIDs);

  const [selectedId, setSelectedId] = useState<string | undefined>(userId);

  useEffect(() => {
    setSelectedId(userId);
  }, [userId]);

  const onChange = (id: string): void => {
    setSelectedId(id);
    navigate('' + id);
  };

  return (
        <Segment className="View survey-picker column" key="survey-picker">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <Form>
                <Form.Field>
                    <label>Participant:</label>
                    <Dropdown
                        placeholder='Select participant'
                        selection
                        search
                        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                        options={participantIds.slice(0).sort((p1, p2) => p1.length - p2.length || p1.localeCompare(p2)).map(id => ({
                          key: id,
                          value: id,
                          text: id
                        }))}
                        value={selectedId}
                        onChange={(e, { value }) => {
                          onChange(value as string);
                        }
                        }
                    />
                </Form.Field>
            </Form>
            <SurveyCalendar />
        </Segment>
  );
}
