import { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { client } from '../../../api/client';
import { useSelector } from 'react-redux';
import { selectAuthUserToken } from '../../../store/reducers/userReducer';
import { Button, Dimmer, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import SurveyAnswerSegment from './SurveyAnswerSegment';
import { jsPDF } from 'jspdf';

interface RatedAnswer {
  label: string
  rating: number | string
}

interface SurveyResponse {
  responses: SurveyData[]
  updates: SurveyUpdate[]
}

interface SurveyData {
  title: string
  question: string
  answer: string | RatedAnswer[]
}

interface SurveyUpdate {
  datetime: number
  message: string
}

interface SurveyDisplayState {
  date: string | undefined
  loadedDate: string | undefined
  userId: string | undefined
  loading: boolean
  error: string | undefined
  surveyResponse: SurveyResponse | undefined
}

export default function SurveyDisplay (): JSX.Element {
  const { userId } = useParams();
  const { date } = useParams();

  const surveyDivRef = useRef<HTMLDivElement>(null);

  const authToken = useSelector(selectAuthUserToken);
  const [surveyDisplayState, setSurveyDisplayState] = useState<SurveyDisplayState>({
    date,
    loadedDate: undefined,
    userId: undefined,
    loading: false,
    error: undefined,
    surveyResponse: undefined
  });

  useEffect(() => {
    if (date !== surveyDisplayState.date) {
      loadSurveyResponses();
    }
  }, [date]);

  const loadSurveyResponses = (): void => {
    if (userId != null && date != null) {
    // Call an API and wait for response
      setSurveyDisplayState({ ...surveyDisplayState, date, userId, error: undefined, loading: true });
      client.get(`/api/participants/${userId}/survey/${date}`, {}, authToken)
        .then(res => {
          setSurveyDisplayState({ ...surveyDisplayState, userId, date, loadedDate: date, error: undefined, loading: false, surveyResponse: res.data });
        })
        .catch(err => {
          console.error(err);
          setSurveyDisplayState({ ...surveyDisplayState, date, error: 'Failed to load the survey', loading: false });
        });
    }
  };

  const downloadSurvey = (): void => {
    const userId = surveyDisplayState.userId;
    const date = surveyDisplayState.loadedDate;
    if (surveyDivRef?.current != null && userId != null && date != null) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      const survey = renderToStaticMarkup(getSurveyResult(false));
      void doc.html(survey, { margin: 15, autoPaging: 'text', width: 200, windowWidth: 650 })
        .then(() => {
          doc.save(`${userId}-survey-${new Date(parseInt(date)).toDateString()}.pdf`);
        });
    }
  };

  const getSurveyDisplay = (): JSX.Element => {
    if (surveyDisplayState.error != null) {
      return <Message error>{surveyDisplayState.error}</Message>;
    }
    if (surveyDisplayState.surveyResponse != null && surveyDisplayState.surveyResponse.responses.length > 0) {
      return (
        <>
        <Button icon onClick={downloadSurvey}>
          <Icon name='download' />
        </Button>
        {getSurveyResult()}
        </>);
    }
    return (<></>);
  };

  const getSurveyResult = (renderIcons = true): JSX.Element => {
    if (surveyDisplayState.surveyResponse != null && surveyDisplayState.surveyResponse.responses.length > 0) {
      return (
        <div ref={surveyDivRef} id='survey-document' className={'survey' + (!renderIcons ? ' pdf-friendly' : '')}>
          <div className='survey-header'>
            <div>Survey Response</div>
            <div>{surveyDisplayState.userId}</div>
            <div>{surveyDisplayState.loadedDate != null ? new Date(parseInt(surveyDisplayState.loadedDate)).toDateString() : ''}</div>
          </div>
          {surveyDisplayState.surveyResponse.responses.map((sd, i) => <SurveyAnswerSegment key={`sas-${i}`} surveyQA={sd} renderIcons={renderIcons}/>)}
          {getSurveyUpdates(renderIcons)}
        </div>);
    }
    return (<></>);
  };

  const getSurveyUpdates = (renderIcons = true): JSX.Element | JSX.Element[] => {
    if (surveyDisplayState.surveyResponse?.updates != null && surveyDisplayState.surveyResponse.updates.length > 0) {
      const updateAsData: SurveyData = {
        title: 'Other',
        question: 'Updates',
        answer: surveyDisplayState.surveyResponse.updates.map((u, i) => {
          return { label: new Date(u.datetime).toLocaleString(), rating: u.message };
        })
      };
      return <SurveyAnswerSegment key='sas-updates' surveyQA={updateAsData} renderIcons={renderIcons} headerLabel='Date' headerRating='Message'/>;
    }
    return <></>;
  };

  return (
    <Segment className="View survey-info" key="survey-info">
        <Dimmer active={surveyDisplayState.loading}>
            <Loader active={true} />
        </Dimmer>
        {getSurveyDisplay()}
    </Segment>
  );
}
