import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Form, Message, Segment } from 'semantic-ui-react';
import { client } from '../../../api/client';
import { selectAuthUserToken } from '../../../store/reducers/userReducer';
import { selectUserByUsername } from '../../../store/reducers/usersSlice';
import { RootState } from '../../../store/store';
import { AdminUserPasswordResetPatch } from '../../../types/UserData';

export default function UserInfo (): JSX.Element {
  // Get the userId param from the URL.
  const { userId } = useParams();
  const [password, setPassword] = useState<string>('**********');
  const [resetError, setResetError] = useState<string | null>(null);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const user = useSelector((state: RootState) => selectUserByUsername(state, userId));
  const authToken = useSelector(selectAuthUserToken);

  // When the user selection (userId) changes - reset the password value shown
  useEffect(() => {
    setPassword('**********');
    setResetError(null);
    setResetLoading(false);
  }, [userId]);

  if (userId === undefined || userId === null) {
    return (
      <Segment className="View User-info">
          Select a user from the list or add a new one
      </Segment>
    );
  }

  if (user == null) {
    return (
      <Segment className="View User-info">
        No user found with this username
      </Segment>
    );
  }

  const resetPassword = (): void => {
    // Call an API and wait for response
    const patchOperation: AdminUserPasswordResetPatch = {
      username: userId,
      patchType: 'AdminPasswordReset'
    };
    setResetLoading(true);
    setResetError(null);
    client.patch('/api/users', patchOperation, {}, authToken)
      .then(res => {
        setPassword(res.data.newPassword);
      })
      .catch(err => {
        console.error(err);
        setResetError('Failed to reset password. Try again later or contact support.');
      })
      .finally(() => setResetLoading(false));
  };

  return (
    <Segment className="View User-info admin">
        <Form>
            <Form.Field>
                <label>Username:</label>
                <input readOnly={true} placeholder='Username' value={userId} />
            </Form.Field>
            <Form.Field>
                <label>Role:</label>
                <input readOnly={true} placeholder='Role' value={user.role} />
            </Form.Field>
            <Form.Field>
                <label>Password:</label>
                <input readOnly={true} value={password} />
            </Form.Field>
            <Message error hidden={resetError === null}>{resetError}</Message>
            <Button onClick={resetPassword} loading={resetLoading} disabled={resetLoading}>Reset Password</Button>
        </Form>
    </Segment>
  );
}
