export interface UserData {
  username: string
  role: UserRole
}

export interface Participant {
  id: string
  deviceIds: string[] | null
}

export interface UserLogin {
  username: string
  password: string
}

export const UserRoles = {
  Admin: 'ADMIN',
  Analyst: 'ANALYST',
  Participant: 'PARTICIPANT',
  Tester: 'TESTER'
} as const;

export type UserRole = typeof UserRoles[keyof typeof UserRoles];

export const Prefixes = {
  BetaTest: 'BT',
  Eradicate: 'ER'
};

export type Prefix = typeof Prefixes[keyof typeof Prefixes];

export interface ParticipantData {
  id: string
  headers: string[]
  data: string[][]
  meta: string[][]
  stepsAvg: string
  respAvg: string
  spo2Avg: string
};

export interface Message {
  epoch: number
  content: string
  sentBy: string
}

export interface AdminUserPasswordResetPatch {
  username: string
  patchType: 'AdminPasswordReset'
};

export interface AdminUserPasswordChangePatch {
  username: string
  oldPassword: string
  newPassword: string
  patchType: 'AdminPasswordChange'
};

export interface MessageTemplate {
  id: string
  content: string
};
