import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dimmer, Form, Icon, Loader, Message, Popup, Segment } from 'semantic-ui-react';
import { client } from '../../../api/client';
import { selectAuthStatus, selectAuthUser, selectAuthUserToken } from '../../../store/reducers/userReducer';
import { RequestStatuses } from '../../../types/status';
import { AdminUserPasswordChangePatch } from '../../../types/UserData';

const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const numberChars = /[0-9]/;

export default function PasswordResetView (): JSX.Element {
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);
  const authToken = useSelector(selectAuthUserToken);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [message, setMessage] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = (): void => {
    if (authUser != null) {
    // Call an API and wait for response
      const patchOperation: AdminUserPasswordChangePatch = {
        username: authUser.username,
        oldPassword,
        newPassword,
        patchType: 'AdminPasswordChange'
      };
      setMessage(null);
      setLoading(true);
      client.patch('/api/users', patchOperation, {}, authToken)
        .then(res => {
          setOldPassword('');
          setNewPassword('');
          setMessage(<Message success>Password changed</Message>);
        })
        .catch(err => {
          console.error(err);
          setMessage(<Message error>Failed to change the password</Message>);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const resetDisabled = (): boolean => {
    return oldPassword.trim().length === 0 ||
    newPassword.trim().length < 9 ||
    oldPassword === newPassword ||
    !specialChars.test(newPassword) ||
    !numberChars.test(newPassword);
  };

  const newPasswordValid = (): boolean => {
    return newPassword.trim().length < 9 ||
        oldPassword === newPassword ||
        !specialChars.test(newPassword) ||
        !numberChars.test(newPassword);
  };

  const getCheckIcon = (correct: boolean): JSX.Element => {
    if (correct) {
      return <Icon name='check' color='green'/>;
    }
    return <Icon name='close' color='red'/>;
  };

  const getPopupContent = (): JSX.Element => {
    return (
        <>
            <div>Password should:</div>
            <div>{getCheckIcon(newPassword.length > 8)} be at least 9 characters</div>
            <div>{getCheckIcon(specialChars.test(newPassword))} contain at least one special character</div>
            <div>{getCheckIcon(numberChars.test(newPassword))} contain at least one numeric character</div>
            <div>{getCheckIcon(oldPassword !== newPassword)} be different from current password</div>
        </>
    );
  };

  return (
    <div className='Page row'>
        <Segment className="View Login-view">
            <Dimmer active={authStatus === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <Form>
                <input id="username" name="username" type="text" value={authUser?.username} style={{ display: 'none' }} />
                <Form.Field>
                    <label>Current Password:</label>
                    <input placeholder='Current Password' type='password'
                      id='current-password'
                      autoComplete='current-password'
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}/>
                </Form.Field>
                <Form.Field error={newPasswordValid()}>
                    <label>New Password {<Popup content={getPopupContent()} trigger={<Icon name='question circle outline' />} />}:</label>
                    <input placeholder='New Password' type='password'
                      id='new-password'
                      autoComplete="new-password"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}/>
                </Form.Field>
                <Button type='submit' disabled={resetDisabled()} onClick={changePassword} loading={loading}>Reset</Button>
            </Form>
            <>{message}</>
        </Segment>
        </div>
  );
}
