import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Form, Message, Segment } from 'semantic-ui-react';
import { isNilOrWhitespace } from '../../../common/utils';
import { addNewParticipant, selectAllParticipants, selectParticipantsError, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { Participant, Prefix, Prefixes } from '../../../types/UserData';

export default function NewParticipantInfo (): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const [prefix, setPrefix] = useState<string>(Prefixes.Eradicate);
  const [id, setId] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [garminId, setGarminId] = useState<string | undefined>(undefined);
  const participants = useSelector(selectAllParticipants);
  const participantsLength = useRef(participants.length);
  const status = useSelector(selectParticipantsStatus);
  const requestError = useSelector(selectParticipantsError);
  const currentStatus = useRef(status);

  const formIsValid = (): boolean => {
    return !isNilOrWhitespace(id);
  };

  const save = (): void => {
    if (formIsValid()) {
      const newParticipant: Participant = {
        id: prefix + (id as string),
        deviceIds: null
      };
      if (garminId != null) {
        newParticipant.deviceIds = [garminId];
      }
      void dispatch(addNewParticipant(newParticipant));
    }
  };

  const cancel = (): void => {
    // Navigate back to parent page
    navigate('..');
  };

  useEffect(() => {
    if (participantsLength.current !== participants.length) {
      // User was saved - navigate to user info view
      navigate('../' + participants[participants.length - 1].id + '');
    }
  }, [navigate, participants]);

  useEffect(() => {
    if (currentStatus.current !== status) {
      // If moving to loading status - remove the error
      // If moving from loading to failure - note the error
      if (currentStatus.current !== RequestStatuses.loading && status === RequestStatuses.loading) {
        setError(undefined);
      } else if (currentStatus.current === RequestStatuses.loading && status === RequestStatuses.failed) {
        setError(requestError);
      }
      currentStatus.current = status;
    }
  }, [status]);

  return (
    <Segment className="View User-info admin">
      <Form >
        <Form.Field >
          <label data-lpignore='true'>ID:</label>
          <Form.Group>
            <Dropdown
              compact
              selection
              options={[{ key: Prefixes.BetaTest, value: Prefixes.BetaTest, text: Prefixes.BetaTest },
                { key: Prefixes.Eradicate, value: Prefixes.Eradicate, text: Prefixes.Eradicate }]}
              value={prefix}
              onChange={(_e: SyntheticEvent, { value }) => {
                // safety check that the selected value is one of the expected enum types,
                // Before it is used as that type
                if (Object.values(Prefixes).includes(value as Prefix)) {
                  setPrefix(value as Prefix);
                }
              }}
            />
            <input
              type='text'
              required={true}
              autoComplete='off'
              data-lpignore='true'
              onChange={(e) => {
                setId(e.target.value);
              }}
              value={id} />
          </Form.Group>
        </Form.Field>
        <Form.Field >
          <label data-lpignore='true'>Garmin ID:</label>
          <input
            type='text'
            autoComplete='off'
            data-lpignore='true'
            onChange={(e) => {
              setGarminId(e.target.value);
            }}
            value={garminId} />
        </Form.Field>
      </Form>
      <Message color='red' hidden={error === undefined}>Error occured. Try again.</Message>
      <div className='list-actions'>
        <Button loading={status === RequestStatuses.loading} onClick={cancel}>Cancel</Button>
        <Button loading={status === RequestStatuses.loading} onClick={save} disabled={!formIsValid()}>Save</Button>
      </div>
    </Segment>
  );
}
