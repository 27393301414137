import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Form, Header, Message, Radio, Segment } from 'semantic-ui-react';
import { selectAllStudiesIDs } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { isNilOrWhitespace } from '../../../common/utils';
import { selectAllParticipantsIDs } from '../../../store/reducers/participantsSlice';
import { selectSendingError, selectSendingStatus, sendGroupMessage, sendMessage, sendMessageToAll } from '../../../store/reducers/messagesSlice';
import { selectAuthUser } from '../../../store/reducers/userReducer';

const RecipientTypes = {
  SingleUser: 'Single User',
  UserGroup: 'User Group',
  Everybody: 'Everybody'
} as const;

export type RecipientType = typeof RecipientTypes[keyof typeof RecipientTypes];

export default function SendMessageView (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const [recipientType, setRecipientType] = useState<RecipientType>(RecipientTypes.SingleUser);
  const [recipientId, setRecipientId] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  const studiesIDs = useSelector(selectAllStudiesIDs);
  const participantsIDs = useSelector(selectAllParticipantsIDs);
  const status = useSelector(selectSendingStatus);
  const requestError = useSelector(selectSendingError);
  const authUser = useSelector(selectAuthUser);
  const currentStatus = useRef(status);

  useEffect(() => {
    if (currentStatus.current !== status) {
      // If moving to loading status - remove the error
      // If moving from loading to failure - note the error
      if (currentStatus.current !== RequestStatuses.loading && status === RequestStatuses.loading) {
        setError(undefined);
      } else if (currentStatus.current === RequestStatuses.loading && status === RequestStatuses.failed) {
        setError(requestError);
      }
      currentStatus.current = status;
    }
  }, [status]);

  const validForm = (recipientId !== undefined || recipientType === RecipientTypes.Everybody) && !isNilOrWhitespace(message);

  const send = (): void => {
    if (validForm && message != null && authUser != null && status !== RequestStatuses.loading) {
      if (recipientType === RecipientTypes.Everybody) {
        void dispatch(sendMessageToAll({ epoch: Date.now(), content: message, sentBy: authUser.username }));
      } else if (recipientId !== undefined) {
        if (recipientType === RecipientTypes.SingleUser) {
          void dispatch(sendMessage({ userId: recipientId, message: { epoch: Date.now(), content: message, sentBy: authUser.username } }));
        } else if (recipientType === RecipientTypes.UserGroup) {
          void dispatch(sendGroupMessage({ studyId: recipientId, message: { epoch: Date.now(), content: message, sentBy: authUser.username } }));
        }
      }
    }
  };

  const getRecipientSelectionRadioGroup = (): JSX.Element => {
    return (
      <Form.Field>
        <label>Recipient Type:</label>
        <Form.Group>
          {
            Object.values(RecipientTypes).map(rt => (
              <Form.Field key={'field-' + rt}>
                <Radio
                  label={rt}
                  key={rt}
                  name='radioGroup'
                  value={rt}
                  checked={recipientType === rt}
                  onChange={(e, { value }) => {
                    setRecipientType(value as RecipientType);
                    setRecipientId(undefined);
                  }}
                />
              </Form.Field>
            ))
          }
        </Form.Group>
      </Form.Field>
    );
  };

  const getRecipientDropdown = (): JSX.Element => {
    let disabled = false;
    if (recipientType === RecipientTypes.Everybody) {
      disabled = true;
    }

    let recipients = participantsIDs;
    if (recipientType === RecipientTypes.UserGroup) {
      recipients = studiesIDs;
    }

    return (
      <Form.Field>
        <label>Recipient:</label>
        <Dropdown
          placeholder='Select recipient'
          search
          disabled={disabled}
          selection
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          options={recipients.slice(0).sort((p1, p2) => p1.length - p2.length || p1.localeCompare(p2)).map(r => ({
            key: r,
            value: r,
            text: r
          }))}
          value={recipientId}
          onChange={(e, { value }) => {
            setRecipientId(value as string);
          }
          }
        />
      </Form.Field>
    );
  };

  return (
    <Segment className="View">
      <Header size='medium'>Send Messages</Header>
      <Form >
        {getRecipientSelectionRadioGroup()}
        {getRecipientDropdown()}
          <Form.Field >
            <label>Message:</label>
            <textarea
                autoComplete='off'
                placeholder='Enter Message'
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message} />
          </Form.Field>
      </Form>
      <Message color='red' hidden={error === undefined}>Error occured. Try again.</Message>
      <div className='list-actions'>
        <Button
          loading={status === RequestStatuses.loading}
          disabled={!validForm || status === RequestStatuses.loading}
          onClick={send}>
            Send
          </Button>
      </div>
    </Segment>
  );
}
