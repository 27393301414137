import { createSlice, createAsyncThunk, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { Participant, ParticipantData } from '../../types/UserData';
import { RootState } from '../store';

interface FetchParticipantDataParams {
  id: string
  startTime: string
  endTime: string
}

// Define a type for the slice state
interface ParticipantIdToData {
  [key: string]: ParticipantData
}

interface UsersState {
  participants: Participant[]
  data: ParticipantIdToData
  status: RequestStatus
  error: string | undefined
}

const initialState: UsersState = {
  participants: [],
  data: {},
  status: RequestStatuses.idle,
  error: undefined
};

export const fetchParticipants = createAsyncThunk('participants/fetchParticipants', async (arg: undefined, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.get('/api/participants', {}, authToken);
  return response.data;
});

export const addNewParticipant = createAsyncThunk(
  'participants/addNewParticipant', async (user: Participant, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post('/api/participants', user, {}, authToken);
    return response.data;
  }
);

export const updateParticipant = createAsyncThunk(
  'participants/updateParticipant', async (user: Participant, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.put('/api/participants', user, {}, authToken);
    return response.data;
  }
);

export const fetchParticipantData = createAsyncThunk('participants/fetchData',
  async (fetchParticipantDataParams: FetchParticipantDataParams, { getState }) => {
    const { id, startTime, endTime } = fetchParticipantDataParams;
    const authToken = (getState() as RootState).userStore.token;
    const params = new URLSearchParams();
    params.set('startTime', startTime);
    params.set('endTime', endTime);
    const response = await client.get('/api/participants/' + id + '/data?' + params.toString(), {}, authToken);
    return response.data;
  });

const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {

  },
  extraReducers (builder: ActionReducerMapBuilder<UsersState>) {
    builder
      .addCase(fetchParticipants.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchParticipants.fulfilled, (state: UsersState, action: PayloadAction<Participant[]>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        // Add any fetched posts to the array
        state.participants = action.payload;
      })
      .addCase(fetchParticipants.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(addNewParticipant.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(addNewParticipant.fulfilled, (state: UsersState, action: PayloadAction<any, any, { arg: Participant }>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        state.participants.push(action.meta.arg);
      })
      .addCase(addNewParticipant.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(updateParticipant.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(updateParticipant.fulfilled, (state: UsersState, action: PayloadAction<any, any, { arg: Participant }>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        // Update the participant in the state
        const updatedParticipant = action.meta.arg;
        const indexToUpdate = state.participants.findIndex(u => u.id === updatedParticipant.id);
        if (indexToUpdate > -1) {
          state.participants[indexToUpdate] = updatedParticipant;
        }
      })
      .addCase(updateParticipant.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(fetchParticipantData.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchParticipantData.fulfilled, (state: UsersState, action: PayloadAction<ParticipantData, any, any>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        state.data[action.meta.arg.id] = action.payload;
      })
      .addCase(fetchParticipantData.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      });
  }
});

export default participantsSlice.reducer;

export const selectParticipantsStatus = (state: RootState): RequestStatus => state.participantsStore.status;
export const selectParticipantsError = (state: RootState): string | undefined => state.participantsStore.error;
export const selectAllParticipants = (state: RootState): Participant[] => state.participantsStore.participants;
export const selectAllParticipantsIDs = (state: RootState): string[] => state.participantsStore.participants.map(u => u.id);

export const selectParticipantById = (state: RootState, userId: string | undefined): Participant | undefined => {
  if (userId === undefined) {
    return undefined;
  }
  return state.participantsStore.participants.find((user: Participant) => user.id === userId);
};

export const selectParticipantData = (state: RootState, id: string | undefined): ParticipantData | undefined => {
  if (id === undefined) {
    return undefined;
  }
  return state.participantsStore.data[id];
};
