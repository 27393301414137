import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import studiesReducer from './reducers/studiesSlice';
import userReducer from './reducers/userReducer';
import usersReducer from './reducers/usersSlice';
import participantsReducer from './reducers/participantsSlice';
import messagesReducer from './reducers/messagesSlice';
import messageTemplatesReducer from './reducers/messageTemplatesReducer';

export const store = configureStore({
  reducer: {
    userStore: userReducer,
    usersStore: usersReducer,
    studiesStore: studiesReducer,
    participantsStore: participantsReducer,
    messagesStore: messagesReducer,
    messageTemplatesStore: messageTemplatesReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
