import React from 'react';
import './style.css';
import { isMobile } from 'react-device-detect';
import { Icon } from 'semantic-ui-react';

interface HeaderProps {
  setMenuOpen: Function
}

function Header (props: HeaderProps): JSX.Element {
  const getContent = (): JSX.Element[] => {
    const content = [];
    if (isMobile) {
      content.push(
        <div className="menu-icon">
          <Icon name='bars' onClick={() => props.setMenuOpen((prev: boolean) => !prev)}/>
        </div>);
    }
    content.push(<div className='title'>HRV-UP Administrator Application</div>);
    return content;
  };

  return (
        <header className="App-header">
          {getContent()}
        </header>
  );
}

export default Header;
