import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Dimmer, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import { RequestStatuses } from '../../../types/status';
import { Participant } from '../../../types/UserData';

interface UsersProps {
  users: Participant[]
}

export default function ParticipantList ({ users }: UsersProps): JSX.Element {
  const { userId } = useParams();

  const navigate = useNavigate();

  const status = useSelector(selectParticipantsStatus);

  const [selectedId, setSelectedId] = useState<string | undefined>(userId);

  // When the user selection (userId) changes - reset the password value shown
  useEffect(() => {
    setSelectedId(userId);
  }, [userId, setSelectedId]);

  const onClick = (id: string): void => {
    setSelectedId(id);
    navigate('' + id);
  };

  const add = (): void => {
    setSelectedId(undefined);
    navigate('add');
  };

  return (
        <Segment className="View User-list" key="user-list">
            <Dimmer active={status === RequestStatuses.loading}>
                <Loader active={true} />
            </Dimmer>
            <List selection verticalAlign='middle' size='big'>
                {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    users.slice(0).sort((u1, u2) => u1.id.length - u2.id.length || u1.id.localeCompare(u2.id)).map(u =>
                        <List.Item key={u.id} onClick={() => onClick(u.id)} active={selectedId === u.id}>
                            <List.Content>
                                <List.Header>{u.id}</List.Header>
                            </List.Content>
                        </List.Item>
                    )
                }
            </List>
            <div className="list-actions">
                <Button icon onClick={add}><Icon name='plus' /></Button>
            </div>
        </Segment>
  );
}
