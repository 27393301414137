import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Segment } from 'semantic-ui-react';
import { selectStudyById } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { DateRange } from 'react-date-range';
import UserList from './UserList';
import StudyUsersModal from './StudyUsersModal';
import { useState } from 'react';

export default function StudyInfo (): JSX.Element {
  // Get the userId param from the URL.
  const { studyId } = useParams();
  const [open, setOpen] = useState(false);
  const study = useSelector((state: RootState) => selectStudyById(state, studyId));

  if (studyId == null) {
    return (
      <Segment className="View User-info">
          Select a study from the list or add a new one
      </Segment>
    );
  }

  if (study == null) {
    return (
      <Segment className="View User-info">
          No study found with this ID
      </Segment>
    );
  }

  const studyRange = {
    startDate: new Date(study.startDate),
    endDate: new Date(study.endDate),
    key: 'selection',
    showDateDisplay: true,
    autoFocus: true,
    disabled: true
  };

  return (
    <>
      <Segment className="View User-info admin">
        <div className='segment-content'>
          <Form>
              <Form.Field>
                  <label>ID:</label>
                  <input readOnly={true} placeholder='ID' value={study.id} />
              </Form.Field>
              <Form.Field>
                  <label>Name:</label>
                  <input readOnly={true} placeholder='Name' value={study.name} />
              </Form.Field>
              <Form.Field >
                  <label data-lpignore='true'>Study Duration:</label>
                  <DateRange
                    className='date-range-input'
                    rangeColors={['#62ddfc']}
                    editableDateInputs={false}
                    months={1}
                    focusedRange={[0, 0]}
                    date={studyRange.startDate}
                    ranges={[studyRange]}
                    dragSelectionEnabled={false}
                    preventSnapRefocus={true}
                    showPreview={false}
                    minDate={studyRange.startDate}
                    maxDate={studyRange.endDate}
                    fixedHeight={true}
                  />
              </Form.Field>
          </Form>
        </div>
      </Segment>
      <UserList setOpen={setOpen} />
      <StudyUsersModal key={studyId} open={open} setOpen={setOpen} studyId={studyId} />
    </>
  );
}
