import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { fetchStudyData, selectStudiesError, selectStudiesStatus, selectStudyDataById } from '../../../store/reducers/studiesSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';
import { StudyData } from '../../../types/StudyTypes';
import DataTable from './DataTable';

export default function StudyOverview (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  // Get the studyId param from the URL.
  const { studyId } = useParams();
  const status = useSelector(selectStudiesStatus);
  const errorMessage = useSelector(selectStudiesError);
  const studyData = useSelector((state: RootState) => selectStudyDataById(state, studyId));

  // Fetch study data overview
  useEffect(() => {
    if (studyId !== undefined && studyData === undefined) {
      void dispatch(fetchStudyData(studyId));
    }
  }, [studyId, dispatch]);

  const getDataTables = (): JSX.Element => {
    if (studyId === undefined) {
      return (
        <>Select a study from the list</>
      );
    }

    if ((studyData !== undefined) && studyData.length !== 0) {
      return (
        <>{studyData.map((sd: StudyData, i: number) => {
          if (sd.title !== undefined) {
            return <DataTable key={i} data={sd} />;
          }
          return <></>;
        }
        )}
        </>
      );
    }

    if (errorMessage !== undefined) {
      return (
        <>{errorMessage}</>
      );
    }

    return (
      <>No Data Available</>
    );
  };

  return (
    <div className="Page data-graphs row data-tables" key="data-overview">
      <Dimmer active={status === RequestStatuses.loading}>
        <Loader active={true} />
      </Dimmer>
      {getDataTables()}
    </div>
  );
}
