import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import { fetchParticipantData, selectParticipantsStatus, selectParticipantData, selectParticipantsError } from '../../../store/reducers/participantsSlice';
import { RootState } from '../../../store/store';
import { RequestStatuses } from '../../../types/status';

interface DataBreakdownProps {
  participantId: string | undefined
  selectedDate: number | undefined
  setSelectedDate: Function
}

/**
 * Displays selected participants data breakdown in a table
 */
export default function DataBreakdown ({ participantId, setSelectedDate }: DataBreakdownProps): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const [searchParams, setSearchParams]: [URLSearchParams, Function] = useSearchParams();

  const status = useSelector(selectParticipantsStatus);
  const errorMessage = useSelector(selectParticipantsError);
  const participantData = useSelector((state: RootState) => selectParticipantData(state, participantId));

  const startTime = searchParams.get('startTime');
  const endTime = searchParams.get('endTime');
  const selectedDate = searchParams.get('selectedDate');

  // Fetch study data overview
  useEffect(() => {
    if (participantId !== undefined && startTime !== null && endTime !== null) {
      const fetchParams = { id: participantId, startTime, endTime };
      void dispatch(fetchParticipantData(fetchParams));
    }
  }, [participantId, startTime, endTime, dispatch]);

  // If selected date changes - scroll to it's location in table
  useEffect(() => {
    if (selectedDate !== undefined && selectedDate !== null) {
      const el = document.getElementById(selectedDate);
      const view = document.getElementById('data-breakdown-table-body');
      if (el !== null && view !== null) {
        const box = el.getBoundingClientRect();
        const isInTheViewport = box.top < window.innerHeight && box.bottom >= 0;
        if (!isInTheViewport) {
          view.scrollTo(0, el.offsetTop - el.offsetHeight);
        }
      }
    }
  }, [selectedDate]);

  const getDataTables = (): JSX.Element => {
    if (participantId === undefined) {
      return (
        <>Select a user from the list</>
      );
    }

    const swapSelectedDate = (dateInMs: string): void => {
      searchParams.set('selectedDate', new Date(dateInMs).toISOString());
      setSearchParams(searchParams);
    };

    if ((participantData !== undefined) && participantData.data.length !== 0) {
      return (
        <Table selectable unstackable>
          <Table.Header>
            <Table.Row>
                {participantData.headers.map(h =>
                    <Table.HeaderCell key={h} className={h}>{h}</Table.HeaderCell>
                )}
            </Table.Row>
          </Table.Header>
          <Table.Body id="data-breakdown-table-body">
            {
              participantData.data.map((dd, i) =>
                  <Table.Row id={new Date(dd[0]).toISOString()} key={i} onClick={() => swapSelectedDate(dd[0])} active={new Date(dd[0]).toISOString() === selectedDate}>
                      {dd.map((d, i) => <Table.Cell key={i} >
                          {
                          participantData.headers[i] === 'Date'
                            ? new Date(d).toLocaleString()
                            : d
                          }
                      </Table.Cell>)}
                  </Table.Row>

              )
            }
          </Table.Body>
        </Table>
      );
    }

    if (errorMessage !== undefined) {
      return (
        <>{errorMessage}</>
      );
    }

    return (
      <>No Data Available</>
    );
  };

  return (
    <Segment className="View data-overview data-breakdown">
      <Dimmer active={status === RequestStatuses.loading}>
        <Loader active={true} />
      </Dimmer>
      {getDataTables()}
    </Segment>
  );
}
