// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client (endpoint, { body, ...customConfig } = {}, authorizationToken = '') {
  const headers = {
    'Content-Type': 'application/json'
  };

  if (authorizationToken !== '') {
    headers.Authorization = 'Bearer ' + authorizationToken;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;
  try {
    const response = await window.fetch(process.env.REACT_APP_API_URL + endpoint, config);
    // response status 204 indicates no content was sent, so do not try to parse the body
    if (config.method !== 'DELETE' && response.ok && response.status !== 204) {
      data = await response.json();
    }
    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url
      };
    } else if (response.status === 401 && !endpoint.includes('login')) {
      // If 401 and not from login page - redirect the user back to login page with
      // expired session param
      window.location = '/login?expired=true';
      throw new Error('Session timed out');
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log('Error', err);
    return Promise.reject(err.message ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}, authToken = '') {
  return client(endpoint, { ...customConfig, method: 'GET' }, authToken);
};

client.post = function (endpoint, body, customConfig = {}, authToken = '') {
  return client(endpoint, { ...customConfig, method: 'POST', body }, authToken);
};

client.put = function (endpoint, body, customConfig = {}, authToken = '') {
  return client(endpoint, { ...customConfig, method: 'PUT', body }, authToken);
};

client.patch = function (endpoint, body, customConfig = {}, authToken = '') {
  return client(endpoint, { ...customConfig, method: 'PATCH', body }, authToken);
};

client.delete = function (endpoint, customConfig = {}, authToken = '') {
  return client(endpoint, { ...customConfig, method: 'DELETE' }, authToken);
};
