import { useEffect } from 'react';
import './style.css';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatuses } from '../../../types/status';
import { fetchStudies, selectStudiesStatus } from '../../../store/reducers/studiesSlice';
import { fetchParticipants, selectParticipantsStatus } from '../../../store/reducers/participantsSlice';
import ViewMessagesView from './ViewMessagesView';
import SendMessageView from './SendMessageView';

export default function Messaging (): JSX.Element {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const status = useSelector(selectStudiesStatus);
  const usersStatus = useSelector(selectParticipantsStatus);

  useEffect(() => {
    if (status === RequestStatuses.idle) {
      void dispatch(fetchStudies());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (usersStatus === RequestStatuses.idle) {
      void dispatch(fetchParticipants());
    }
  }, [usersStatus, dispatch]);

  return (
    <div className="Messaging Page row">
      <ViewMessagesView />
      <SendMessageView />
    </div>
  );
}
