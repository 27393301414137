import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { UserData, UserLogin } from '../../types/UserData';
import { RootState } from '../store';

// Define a type for the slice state
interface UserState {
  user: UserData | undefined
  token: string | undefined
  status: RequestStatus
  error: string | undefined
}

// Define the initial state using that type
const initialState: UserState = {
  user: undefined,
  token: undefined,
  status: RequestStatuses.idle,
  error: undefined
};

export const login = createAsyncThunk(
  'users/login',
  async (user: UserLogin) => {
    const response = await client.post('/api/login', user);
    return response.data;
  }
);

export const logout = createAsyncThunk(
  'users/logout',
  async (arg, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.delete('/api/login', {}, authToken);
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers (builder: ActionReducerMapBuilder<UserState>) {
    builder
      .addCase(login.pending, (state: UserState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(login.fulfilled, (state: UserState, action: PayloadAction<{ user: UserData, token: string }>) => {
        state.status = RequestStatuses.success;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state: UserState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(logout.pending, (state: UserState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(logout.fulfilled, (state: UserState, action: PayloadAction<UserData>) => {
        state.status = RequestStatuses.success;
        state.user = undefined;
      })
      .addCase(logout.rejected, (state: UserState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
        state.user = undefined;
        state.token = undefined;
      });
  }
});

export const selectAuthStatus = (state: RootState): RequestStatus => state.userStore.status;
export const selectAuthUser = (state: RootState): UserData | undefined => state.userStore.user;
export const selectAuthUserToken = (state: RootState): string | undefined => state.userStore.token;
export const selectAuthError = (state: RootState): string | undefined => state.userStore.error;

export default userSlice.reducer;
