import { createSlice, createAsyncThunk, ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { RequestStatus, RequestStatuses } from '../../types/status';
import { UserData } from '../../types/UserData';
import { RootState } from '../store';

// Define a type for the slice state
interface UsersState {
  users: UserData[]
  status: RequestStatus
  error: string | undefined
}

const initialState: UsersState = {
  users: [],
  status: RequestStatuses.idle,
  error: undefined
};

export const fetchUsers = createAsyncThunk('posts/fetchUsers', async (arg: undefined, { getState }) => {
  const authToken = (getState() as RootState).userStore.token;
  const response = await client.get('/api/users', {}, authToken);
  return response.data;
});

export const addNewUser = createAsyncThunk(
  'posts/addNewUser',
  async (user: UserData, { getState }) => {
    const authToken = (getState() as RootState).userStore.token;
    const response = await client.post('/api/users', user, {}, authToken);
    return response.data;
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {

  },
  extraReducers (builder: ActionReducerMapBuilder<UsersState>) {
    builder
      .addCase(fetchUsers.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(fetchUsers.fulfilled, (state: UsersState, action: PayloadAction<UserData[]>) => {
        state.status = RequestStatuses.success;
        // Add any fetched posts to the array
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      })
      .addCase(addNewUser.pending, (state: UsersState) => {
        state.status = RequestStatuses.loading;
      })
      .addCase(addNewUser.fulfilled, (state: UsersState, action: PayloadAction<any, any, { arg: UserData }>) => {
        state.error = undefined;
        state.status = RequestStatuses.success;
        state.users.push(action.meta.arg);
      })
      .addCase(addNewUser.rejected, (state: UsersState, action: PayloadAction<any, any, any, any>) => {
        state.status = RequestStatuses.failed;
        state.error = action.error.message;
      });
  }
});

export default usersSlice.reducer;

export const selectUsersStatus = (state: RootState): RequestStatus => state.usersStore.status;
export const selectUsersError = (state: RootState): string | undefined => state.usersStore.error;
export const selectAllUsers = (state: RootState): UserData[] => state.usersStore.users;
export const selectAllUsersUsernames = (state: RootState): string[] => state.usersStore.users.map(u => u.username);

export const selectUserByUsername = (state: RootState, username: string | undefined): UserData | undefined =>
  state.usersStore.users.find((user: UserData) => user.username === username);
